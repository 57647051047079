import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// slices
import SourcesReducer from './slices/sources';
import busesReducer from './slices/buses';
import buslayoutReducer from './slices/buslayout';
import busSeatBlockReducer from './slices/busSeatBlock';
// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const rootReducer = combineReducers({
    sources: SourcesReducer,
    buses: busesReducer,
    buslayout: buslayoutReducer,
    busSeatBlock: busSeatBlockReducer,
});

export { rootPersistConfig, rootReducer };
