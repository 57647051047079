import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import httpCommon from "../../http-common";

const BookingContent = () => {
    const [getBookings, setBookings] = useState([]);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser();
    }, [])
    const getUser = async () => {
        try {
            const VerifyUser = await localStorage.getItem("VerifyUser");
            const APiTokenData = {
                api_token: VerifyUser
            };
            const getUser = await httpCommon.post("/getuserbytoken/user", APiTokenData);
            if (getUser.data?.user) {
                setUser(getUser.data?.user)
            }
            console.log(getUser.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    const Bookings = async (userId) => {
        try {
            const getOrders = await httpCommon.get("/users/orders/" + userId);
            setBookings(getOrders.data);
            console.log(getOrders.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (user) {
            Bookings(user.user_id);
        }
    }, [user])
    return (
        <>
            {/* Page content starts */}
            <div className="card" data-card-height="cover" style={{ marginTop: '50px' }}>
                {getBookings.length > 0 && getBookings.map((bookings, i) => {
                    return (
                        <div key={i} className="content mb-1 mt-0">
                            <div className="timeline-body timeline-body-center bg-white pb-1 pt-0">
                                <div className="timeline-item mt-2">
                                    <div
                                        className="timeline-item-content rounded-s shadow-l mt-1"
                                        style={{ padding: 15 }}
                                    >
                                        <div className="accordion" id="accordion-1">
                                            <div className="mb-0">
                                                <button
                                                    className="btn accordion-btn border-0 color-theme mr-1 p-1"
                                                    data-toggle="collapse"
                                                    data-target={"#collapse" + i}
                                                >
                                                    <div>
                                                        <i className="fa fa-bus mr-2 font-18" aria-hidden="true" />
                                                        {bookings?.from_city_name} &nbsp;
                                                        <i className="fa fa-arrow-right" aria-hidden="true" />&nbsp;
                                                        <i className="fa fa-chevron-down font-10 accordion-icon" />&nbsp;
                                                        {bookings?.to_city_name}
                                                        <span className="ml-5">₹ {Number(bookings?.total_fare)}/-</span>
                                                    </div>
                                                    <div style={{marginLeft:'25px'}}>
                                                        Journey Date 
                                                        <span className="float-right">{bookings?.journey_date}</span>
                                                    </div>
                                                </button>
                                                <div
                                                    id={"collapse" + i}
                                                    className="collapse"
                                                    data-parent="#accordion-1"
                                                >
                                                    <hr className="mb-0" />
                                                    <div className="py-2 pl-3 pr-3">
                                                        <p className="mb-0 font-13">Boarding point</p>
                                                        <p className="mb-1 font-10 row" style={{ color: "#ff6600" }}>
                                                            <span className="col-9 font-weight-bold">{bookings?.boarding_point}</span>
                                                            <span className="col-3 font-weight-bold text-center">{bookings?.journey_date}, {bookings?.boarding_time}</span>
                                                        </p>
                                                        <p className="mb-0 font-13">Dropping point</p>
                                                        <p className="mb-0 font-10 row" style={{ color: "#ff6600" }}>
                                                            <span className="col-9 font-weight-bold">{bookings.droping_point}</span>
                                                            <span className="col-3 font-weight-bold text-center">{bookings?.droping_time}</span>
                                                        </p>
                                                        <hr className="mb-0 " />
                                                        <p className="font-14 mt-1 mb-0 text-center text-dark">
                                                            Travellers
                                                        </p>
                                                        <p className="font-13 mt-1 mb-2">
                                                            <u>Name</u>
                                                            <span className="float-right">
                                                                <u>Seat.No</u>
                                                            </span>
                                                        </p>
                                                        {bookings?.busbookingpassengers.map && bookings?.busbookingpassengers?.map((pasengers, k) => {
                                                            return <p key={k} className="mb-1" style={{ color: "#ff6600" }}>
                                                                {pasengers?.passenger_name}, {pasengers?.passenger_age}, {pasengers?.passenger_gender}
                                                                <span className="float-right">
                                                                    <b>{pasengers?.seat_name}</b>
                                                                </span>
                                                            </p>
                                                        })}
                                                        <hr className="mb-2 " />
                                                        <p className="font-14 mt-1 mb-0 text-center text-dark">
                                                            Price
                                                        </p>
                                                        <p className="mb-1" style={{ color: "#ff6600" }}>
                                                            Total Fare<span className="float-right font-weight-bold">(₹ {Number(bookings?.total_fare)}/-)</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                {getBookings.length === 0 &&
                    <div className="card-center text-center">
                        <img src="assets/images/travel/nobookings.png" alt="" className="bookngs-img" />
                        <h4 className="font-22 mt-n2 color-highlight pt-4 mb-4">
                            You have no bookings yet {getBookings.length}
                        </h4>
                        <Link to="/main" className="btn-sm  mb-3 mt-5 rounded-xs text-uppercase font-400 shadow-s color-white custom-btn">
                            Book Now
                        </Link>
                    </div>
                }
            </div>
            {/* Page content ends */}

        </>
    )
}

export { BookingContent };