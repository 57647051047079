import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavThree } from "../navs/topnavthree";
import { GetConfirm } from "./getconfirm";
// import { TopNavTwo } from "../navs/topnavtwo";



const BookingConfirm = () => {
    return(
        <>
            <TopNavThree />
            <FooterNav children={"main"}/>
            <GetConfirm/>
            <FooterScript/>
        </>
    )
}

export {BookingConfirm};