const FlightBookingContent = () => {
    return(
        <>
            <div className="page-content header-clear">
                <div className="tab-wrapper">
                    <div className="content">
                        <div className="tab-controls tabs-round tab-animated tabs-medium tabs-rounded shadow-xl" data-tab-items={2} data-tab-active="color-white bg-secondary">
                            <a href="#" data-tab-active data-tab="tab-8">One-Way</a>
                            <a href="#" data-tab="tab-9" style={{backgroundColor: '#fff'}}>Round Trip</a>
                        </div>
                        <div className="clearfix mb-3" />
                        <div className="tab-content" id="tab-8">
                            <p className="bottom-0">
                            </p>
                            <div className="card p-2">
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fa fa-plane pl-2" style={{fontSize: 'larger', bottom: '28px'}} />
                                    <span className="color-highlight">Source</span>
                                    <input type="name" className="pl-5" data-menu="menu-share" placeholder="Enter Source" />
                                </div>
                                <img src="assets/images/travel/arrow.png" alt="" width="30px" style={{position: 'absolute', top: '44px', backgroundColor: 'white', right: '11px'}} /> 
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fa fa-plane ml-2 pl-2" style={{fontSize: 'larger', bottom: '28px', transform: 'scaleX(-1)'}} />
                                    <span className="color-highlight">Destination</span>
                                    <input type="name" className="pl-5" data-menu="menu-search" placeholder="Enter Destinantion" />
                                </div>
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fa fa-calendar pl-2" style={{fontSize: 'larger', bottom: '32px'}} />
                                    <input type="date" className="pl-5 pt-3" defaultValue="1980-08-26" />
                                </div>
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fa fa-users pl-2" style={{fontSize: 'larger', bottom: '28px'}} />
                                    <span className="color-highlight">Travellers</span>
                                    <input className="pl-5" data-menu="menu-traveller" placeholder="Travellers" readOnly />
                                </div>
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fas fa-ticket-alt pl-2" style={{fontSize: 'larger', bottom: '28px'}} />
                                    <span className="color-highlight">Class</span>
                                    <input className="pl-5" data-menu="menu-class" placeholder="Economy" readOnly />
                                </div>
                                <a href="#" className="btn btn-full btn-xs rounded-sm  font-500 text-uppercase shadow-xl" style={{backgroundColor: '#ff6600'}}>Search</a>
                            </div>
                            <p />
                        </div>
                        <div className="tab-content" id="tab-9">
                            <p className="bottom-0">
                            </p>
                            <div className="card p-2">
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fa fa-plane pl-2" style={{fontSize: 'larger', bottom: '28px'}} />
                                    <span className="color-highlight">Source</span>
                                    <input type="name" className="pl-5" data-menu="menu-share" placeholder="Enter Source" />
                                </div>
                                <img src="assets/images/travel/arrow.png" alt="" width="30px" style={{position: 'absolute', top: '44px', backgroundColor: 'white', right: '11px'}} /> 
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fa fa-plane pl-2 ml-2" style={{fontSize: 'larger', bottom: '28px', transform: 'scaleX(-1)'}} />
                                    <span className="color-highlight">Destination</span>
                                    <input type="name" className="pl-5" data-menu="menu-search" placeholder="Enter Destination" />
                                </div>
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fa fa-calendar pl-2" style={{fontSize: 'larger', bottom: '32px'}} />
                                    {/* <span class="color-highlight">Select Date</span> */}
                                    <em><i className="fa fa-angle-down" /></em>
                                    <input type="date" className="pl-5 pt-3" defaultValue="1980-08-26" />
                                </div> 
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fa fa-calendar pl-2" style={{fontSize: 'larger', bottom: '32px'}} />
                                    {/* <span class="color-highlight">Select Date</span> */}
                                    <em><i className="fa fa-angle-down" /></em>
                                    <input type="date" className="pl-5 pt-3" defaultValue="1980-08-26" />
                                </div> 
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fa fa-users pl-2" style={{fontSize: 'larger', bottom: '28px'}} />
                                    <span className="color-highlight">Travellers</span>
                                    <input type="name" className="pl-5" data-menu="menu-traveller" placeholder="Travellers" readOnly />
                                </div>
                                <div className="input-style has-icon input-style-1 input-required">
                                    <i className="input-icon fas fa-ticket-alt pl-2" style={{fontSize: 'larger', bottom: '28px'}} />
                                    <span className="color-highlight">Class</span>
                                    <input type="name" className="pl-5" data-menu="menu-class" placeholder="Economy" readOnly />
                                </div>
                                <a href="#" className="btn btn-full btn-xs rounded-sm  font-500 text-uppercase shadow-xl" style={{backgroundColor: '#ff6600'}}>Search</a>
                            </div>
                            <p />
                        </div>
                    </div>
                </div>
                <div className="divider divider-margins" />
                <div className="content mb-0">
                    <div className="card card-style shadow-xl" data-card-height={175} style={{backgroundImage: 'url("assets/images/travel/air.jpg")'}}>
                        <div className="card-overlay bg-black opacity-10" />
                        <div className="card-bottom m-3">
                            <p className="color-white mb-0" style={{color: '#ff6600'}}>Long Weekends Ahead</p>
                            <h1 className="color-white mb-0 pb-0">Avail Offer On Your<br />First Flight Booking</h1>
                        </div>
                    </div>
                </div>
                <div className="divider divider-margins" />
            </div>

            {/* Search Modal Starts Menu*/}
            <div id="menu-share" className="menu menu-box-modal" data-menu-height="100%" data-menu-width="100%" data-menu-effect="menu-over">
                <div className="menu-title mt-1 mb-1">
                    <h4 className="my-0 py-0 p-3">Select Source</h4>
                    <a href="#" className="close-menu" style={{color: '#ff6600'}}><i className="fa fa-times-circle" /></a>
                </div>
                <div className="page-content">
                    <div className="content mt-0 mb-0">
                        <div className="search-box search-dark shadow-xl border-0 bg-theme rounded-sm bottom-0">
                            <i className="fa fa-search" />
                            <input type="text" className="border-0" placeholder="Search" data-search />
                        </div>
                    </div>
                    <div className="divider divider-margins" />
                    <div className="content">
                        <h4>POPULAR CITIES</h4><br />
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Hyderabad</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Chandigarh</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Mumbai</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Bangalore</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Pune</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Chennai</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Indore</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Kolkata</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Kochi</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Jaipur</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Lucknow</span></p>
                    </div>
                </div>
            </div>
            {/* Search Modal Ends Menu*/}

            {/* Search Modal2 Starts Menu*/}
            <div id="menu-search" className="menu menu-box-modal" data-menu-height="100%" data-menu-width="100%" data-menu-effect="menu-over">
                <div className="menu-title mt-1 mb-1">
                    <h4 className="my-0 py-0 p-3">Select Destination</h4>
                    <a href="#" className="close-menu" style={{color: '#ff6600'}}><i className="fa fa-times-circle" /></a>
                </div>
                <div className="page-content">
                    <div className="content mt-0 mb-0">
                        <div className="search-box search-dark shadow-xl border-0 bg-theme rounded-sm bottom-0">
                            <i className="fa fa-search" />
                            <input type="text" className="border-0" placeholder="Search" data-search />
                        </div>
                    </div>
                    <div className="divider divider-margins" />
                    <div className="content">
                        <h4>POPULAR CITIES</h4><br />
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Hyderabad</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Chandigarh</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Mumbai</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Bangalore</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Pune</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Chennai</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Hyderabad</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Kolkata</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Kochi</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Jaipur</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-location-arrow pl-1 pr-2" /><span>Lucknow</span></p>
                    </div>
                </div>
            </div>
            {/* Search Modal2 Ends Menu*/}

            {/* Search Modal3 Starts Menu*/}
            <div id="menu-class" className="menu menu-box-modal" data-menu-height={230} data-menu-width={350} data-menu-effect="menu-over" style={{overflow: 'unset'}}>
                <div className="menu-title mt-1 mb-1">
                    <h4 className="my-0 py-0 p-3">Select a Class</h4>
                    {/* <a href="#" class="close-menu" style="color:#ff6600"><i class="fa fa-times-circle"></i></a> */}
                </div>
                <div className="page-content">
                    <div className="content mt-0">
                        <div className="form-check icon-check">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" defaultValue id="radio4" />
                            <label className="form-check-label text-dark text-dark" htmlFor="radio4" style={{fontSize: '15px', fontWeight: 600}}>&nbsp;&nbsp;&nbsp;Economy</label>
                            <i className="icon-check-1 far fa-circle font-16" style={{color: '#ff6600'}} />
                            <i className="icon-check-2 far fa-check-circle font-16 color-highlight" />
                        </div>
                        <div className="form-check icon-check">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" defaultValue id="radio5" />
                            <label className="form-check-label text-dark" htmlFor="radio5" style={{fontSize: '15px', fontWeight: 600}}>&nbsp;&nbsp;&nbsp;Bussiness</label>
                            <i className="icon-check-1 far fa-circle font-16" style={{color: '#ff6600'}} />
                            <i className="icon-check-2 far fa-check-circle font-16 color-highlight" />
                        </div>
                        <div className="form-check icon-check">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" defaultValue id="radio6" />
                            <label className="form-check-label text-dark" htmlFor="radio6" style={{fontSize: '15px', fontWeight: 600}}>&nbsp;&nbsp;&nbsp;First Class</label>
                            <i className="icon-check-1 far fa-circle font-16" style={{color: '#ff6600'}} />
                            <i className="icon-check-2 far fa-check-circle font-16 color-highlight" />
                        </div>
                        <div className="form-check icon-check">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" defaultValue id="radio7" />
                            <label className="form-check-label text-dark" htmlFor="radio7" style={{fontSize: '15px', fontWeight: 600}}>&nbsp;&nbsp;&nbsp;Premium Economy</label>
                            <i className="icon-check-1 far fa-circle font-16" style={{color: '#ff6600'}} />
                            <i className="icon-check-2 far fa-check-circle font-16 color-highlight" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Search Modal3 Ends Menu*/}

            {/* Search Modal4 Starts Menu*/}
            <div id="menu-traveller" className="menu menu-box-modal pl-2" data-menu-height={370} data-menu-width={350} data-menu-effect="menu-over" style={{overflow: 'unset'}}>
                <div className="menu-title mt-1 mb-1">
                    <h4 className="my-0 py-0 p-3">Add passengers</h4>
                    {/* <a href="#" class="close-menu" style="color:#ff6600"><i class="fa fa-times-circle"></i></a> */}
                </div>
                <div className="page-content">
                    <div className="content mt-0">
                        <div className="row">
                            <div className="col-6 ">
                                <h5>Adult</h5>
                                <p>12 years old &amp; above</p>
                            </div>
                            <div className="col-6">
                                <div className="mx-auto">
                                    <div className="stepper rounded-s float-right">
                                        <a href="#" className="stepper-sub"><i className="fa fa-minus color-red-dark" /></a>
                                        <input type="number" min={1} max={99} defaultValue={1} />
                                        <a href="#" className="stepper-add"><i className="fa fa-plus color-green-dark" /></a>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 ">
                                <h5>Children</h5>
                                <p>2 to 12 years</p>
                            </div>
                            <div className="col-6">
                                <div className="mx-auto">
                                    <div className="stepper rounded-s float-right">
                                        <a href="#" className="stepper-sub"><i className="fa fa-minus color-red-dark" /></a>
                                        <input type="number" min={1} max={99} defaultValue={1} />
                                        <a href="#" className="stepper-add"><i className="fa fa-plus color-green-dark" /></a>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <h5>Infants</h5>
                                <p>less than 2 years</p>
                            </div>
                            <div className="col-6">
                                <div className="mx-auto">
                                    <div className="stepper rounded-s float-right">
                                        <a href="#" className="stepper-sub"><i className="fa fa-minus color-red-dark" /></a>
                                        <input type="number" min={1} max={99} defaultValue={1} />
                                        <a href="#" className="stepper-add"><i className="fa fa-plus color-green-dark" /></a>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <a href="#" className="btn btn-xs btn-full mb-1 rounded-xs text-uppercase font-600 shadow-s " style={{background: 'linear-gradient(227deg, rgba(230, 92, 0, 0.45) 0%, rgba(255, 102, 0, 0.79) 61%, rgba(255, 117, 26, 0.77) 100%)'}}>cancel</a>
                            </div>
                            <div className="col-6">
                                <a href="#" className="btn btn-xs btn-full mb-1 rounded-xs text-uppercase font-600 shadow-s " style={{background: 'linear-gradient(227deg, rgba(230, 92, 0, 0.45) 0%, rgba(255, 102, 0, 0.79) 61%, rgba(255, 117, 26, 0.77) 100%)'}}>save</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Search Modal4 Ends Menu*/}

        </>
    )
}

export {FlightBookingContent}