import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import format from "date-fns/format";
import httpCommon from '../../http-common';
// utils
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    BusSeatBlock: [],
    BusSeatBook: [],
};

const slice = createSlice({
    name: 'vrlSources',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SeatBlock
        getSuccessBusSeatBlock(state, action) {
            state.isLoading = false;
            state.BusSeatBlock = action.payload;
        },

        // GET SeatBook
        getSuccessBusSeatBook(state, action) {
            state.isLoading = false;
            state.BusSeatBook = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getVrlBusSeatBlock(busData) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            console.log(busData);
            var price = 0;
            var GST = 0;
            var priceWithGST = 0;
            let SeatData = "";
            var SELECTEDSEATS = busData.SELECTEDSEATS;
            let i = 0;
            SELECTEDSEATS?.map && SELECTEDSEATS.map((data) => {
                price = +price + +data.BASE_FARE;
                GST = +GST + +data.GST_TAX;
                priceWithGST = +priceWithGST + +data.BASE_FAREWITH_GST_TAX;
                if (i !== 0) {
                    SeatData += "|";
                }
                SeatData += "" + data?.SEATNAME + ",";
                if(busData?.USERDETAILS[i]?.GENDER === "Male"){
                    SeatData += "M"
                }
                else{
                    SeatData += "F"
                }
                i += 1;
            })
            const VrlData = {
                "verifyCall": process.env.REACT_APP_VRLVerifyCall,
                "ReferenceNumber": busData?.busData?.REFERENCENUMBER,
                "PassengerName": busData?.USERDETAILS[0]?.NAME,
                "SeatNames": SeatData,
                "Email": busData?.USEREMAIL,
                "Phone": busData?.USERPHONE,
                "PickupID": busData.SELECTEDBORDINGPOINT.STOPID,
                "PayableAmount": priceWithGST,
                "TotalPassengers": busData?.USERDETAILS.length,
            }            
            // console.log(OrangeData);
            dispatch(slice.actions.getSuccessBusSeatBlock(VrlData));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOrangeBusSeatBlock(busData) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            console.log(busData);
            var price = 0;
            var GST = 0;
            var priceWithGST = 0;
            let SeatData = "";
            var SELECTEDSEATS = busData.SELECTEDSEATS;
            let i = 0;
            SELECTEDSEATS?.map && SELECTEDSEATS.map((data) => {
                price = +price + +data.BASE_FARE;
                GST = +GST + +data.GST_TAX;
                priceWithGST = +priceWithGST + +data.BASE_FAREWITH_GST_TAX;
                if (i !== 0) {
                    SeatData += "|";
                }
                SeatData += "" + data?.SEATNAME + ",";
                if(busData?.USERDETAILS[i]?.GENDER === "Male"){
                    SeatData += "M"
                }
                else{
                    SeatData += "F"
                }
                i += 1;
            })
            const OrangeData = {
                "verifyCall": process.env.REACT_APP_OrangeVerifyCall,
                "ReferenceNumber": busData?.busData?.REFERENCENUMBER,
                "PassengerName": busData?.USERDETAILS[0]?.NAME,
                "SeatNames": SeatData,
                "Email": busData?.USEREMAIL,
                "Phone": busData?.USERPHONE,
                "PickupID": busData.SELECTEDBORDINGPOINT.STOPID,
                "PayableAmount": priceWithGST,
                "TotalPassengers": busData?.USERDETAILS.length,
            }
            // console.log(OrangeData);
            dispatch(slice.actions.getSuccessBusSeatBlock(OrangeData));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOrangeBusSeatBook(busData) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            console.log(busData);
            var price = 0;
            var GST = 0;
            var priceWithGST = 0;
            let SeatData = "";
            var SELECTEDSEATS = busData.SELECTEDSEATS;
            let i = 0;
            let SeatDetails = '';
            let paxDetails = [];
            SELECTEDSEATS?.map && SELECTEDSEATS.map((data) => {
                price = +price + +data.BASE_FARE;
                GST = +GST + +data.GST_TAX;
                priceWithGST = +priceWithGST + +data.BASE_FAREWITH_GST_TAX;
                if (i !== 0) {
                    SeatData += "|";
                    SeatDetails += "|";
                }
                SeatData += "" + data?.SEATNAME + ",";
                if(busData?.USERDETAILS[i]?.GENDER === "Male"){
                    SeatData += "M"
                }
                else{
                    SeatData += "F"
                }
                SeatDetails += "" + data?.SEATNAME + "," + busData?.USERDETAILS[i]?.NAME + "," + busData?.USERPHONE + "," + busData?.USERDETAILS[i]?.AGE;
                const paxSeats = {
                    "seatName": data?.SEATNAME,
                    "paxName": busData?.USERDETAILS[i]?.NAME,
                    "mobileNo": busData?.USERPHONE,
                    "paxAge": Number(busData?.USERDETAILS[i]?.AGE),
                    "baseFare": data.BASE_FARE,
                    "gstFare": 0,
                    "totalFare": data.BASE_FAREWITH_GST_TAX,
                    "idProofID": 0,
                    "idProofDetails": "",
                }
                i += 1;
                paxDetails.push(paxSeats);
            })
            const OrangeData = {
                "verifyCall": process.env.REACT_APP_OrangeVerifyCall,
                "ReferenceNumber": busData?.busData?.REFERENCENUMBER,
                "PassengerName": busData?.USERDETAILS[0]?.NAME,
                "SeatNames": SeatData,
                "Email": busData?.USEREMAIL,
                "Phone": busData?.USERPHONE,
                "PickupID": Number(busData.SELECTEDBORDINGPOINT.STOPID),
                "PayableAmount": priceWithGST,
                "TotalPassengers": busData?.USERDETAILS.length,
                "DropID": Number(busData.SELECTEDDROPINGPOINT.STOPID),
                "Discount": 0,
                "SeatDetails": SeatDetails,
                "GSTState": 0,
                "GSTCompanyName": null,
                "GSTRegNo": null,
                "APIPNRNo": "OT001",
                "paxDetails": paxDetails,
            }
            // console.log(OrangeData);
            dispatch(slice.actions.getSuccessBusSeatBook(OrangeData));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getVrlBusSeatBook(busData) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            console.log(busData);
            var price = 0;
            var GST = 0;
            var priceWithGST = 0;
            let SeatData = "";
            var SELECTEDSEATS = busData.SELECTEDSEATS;
            let i = 0;
            let SeatDetails = '';
            SELECTEDSEATS?.map && SELECTEDSEATS.map((data) => {
                price = +price + +data.BASE_FARE;
                GST = +GST + +data.GST_TAX;
                priceWithGST = +priceWithGST + +data.BASE_FAREWITH_GST_TAX;
                if (i !== 0) {
                    SeatData += "|";
                    SeatDetails += "|";
                }
                SeatData += "" + data?.SEATNAME + ",";
                if(busData?.USERDETAILS[i]?.GENDER === "Male"){
                    SeatData += "M"
                }
                else{
                    SeatData += "F"
                }
                SeatDetails += "" + data?.SEATNAME + "," + busData?.USERDETAILS[i]?.NAME + "," + busData?.USERPHONE + "," + busData?.USERDETAILS[i]?.AGE;
                i += 1;
            })
            const VrlData = {
                "verifyCall": process.env.REACT_APP_VRLVerifyCall,
                "ReferenceNumber": busData?.busData?.REFERENCENUMBER,
                "PassengerName": busData?.USERDETAILS[0]?.NAME,
                "SeatNames": SeatData,
                "Email": busData?.USEREMAIL,
                "Phone": busData?.USERPHONE,
                "PickupID": busData.SELECTEDBORDINGPOINT.STOPID,
                "PayableAmount": priceWithGST,
                "TotalPassengers": busData?.USERDETAILS.length,
                "DropID": busData.SELECTEDDROPINGPOINT.STOPID,
                "Discount": 0,
                "SeatDetails": SeatDetails,
                "GSTState": 0,
                "GSTCompanyName": null,
                "GSTRegNo": null,
                "APIPNRNo": "OT001",
            }
            // console.log(OrangeData);
            dispatch(slice.actions.getSuccessBusSeatBook(VrlData));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}