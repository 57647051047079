import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import httpCommon from "../../http-common";
import { baseUrl } from "../../http-common";
import { getVrlBusSeatBlock, getOrangeBusSeatBlock, getOrangeBusSeatBook, getVrlBusSeatBook } from "../../redux/slices/busSeatBlock";
import { useDispatch, useSelector } from "../../redux/store";


//Payment Gateway
function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
}

function isObj(val) {
    return typeof val === 'object'
}

function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
        return JSON.stringify(val)
    } else {
        return val
    }
}

function buildForm(details) {
    console.log(details);
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', details.action)

    Object.keys(details.params).forEach(key => {
        const input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', stringifyValue(details.params[key]))
        form.appendChild(input)
    })

    return form
}

function post(details) {
    const form = buildForm(details)
    // console.log(form);
    document.body.appendChild(form);
    form.submit()
    form.remove()
}

const GetPayment = (children) => {

    const location = useLocation();
    // console.log(children);
    // console.log(location.state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [getData, setData] = useState([]);
    const [getPrice, setPrice] = useState(0);
    const [getGST, setGST] = useState(0);
    const [getPriceWithGST, setPriceWithGST] = useState(0);
    const [getDropingPoint, setDropingPoint] = useState([]);
    const [getBoardingPoint, setBoardingPoint] = useState([]);
    const [getSelectedSeats, setSelectedSeats] = useState([]);
    const [getPassengers, setPassengers] = useState([]);
    const [getLoad, setLoad] = useState(true);
    const [getError, setError] = useState(false);
    const [seatBlockError, setSeatBlockError] = useState(true);
    const [seatBlockErrorData, setSeatBlockErrorData] = useState('');
    const [getBusSeatBlock, setBusSeatBlock] = useState([]);
    const [getUserData, setUser] = useState([]);

    const { BusSeatBlock, BusSeatBook, isLoading } = useSelector((state) => state.busSeatBlock);

    useEffect(() => {

        if (!location.state) {
            window.location.reload();
            navigate('/busbooking');
        } else {
            getUser();
            console.log(location.state);
            setDropingPoint(location.state.SELECTEDDROPINGPOINT);
            setBoardingPoint(location.state.SELECTEDBORDINGPOINT);
            setSelectedSeats(location.state.SELECTEDSEATS);
            setPassengers(location.state.USERDETAILS);
            setData(location.state);
            var price = 0;
            var GST = 0;
            var priceWithGST = 0;
            var SELECTEDSEATS = location.state.SELECTEDSEATS;
            SELECTEDSEATS?.map && SELECTEDSEATS.map((data) => {
                price = +price + +data.BASE_FARE;
                GST = +GST + +data.GST_TAX;
                priceWithGST = +priceWithGST + +data.BASE_FAREWITH_GST_TAX;
            })
            setPrice(price);
            setGST(GST);
            setPriceWithGST(priceWithGST);
            if (location.state.busData.TRAVELLSNAME === "Orange Travels") {
                dispatch(getOrangeBusSeatBlock(location.state));
                dispatch(getOrangeBusSeatBook(location.state));
            }
            if (location.state.busData.TRAVELLSNAME === "VRL Travels") {
                dispatch(getVrlBusSeatBlock(location.state));
                dispatch(getVrlBusSeatBook(location.state));
            }
        }
    }, [])

    const getUser = async () => {
        try {
            const VerifyUser = await localStorage.getItem("VerifyUser");
            const APiTokenData = {
                api_token: VerifyUser
            };
            const getUser = await httpCommon.post("/getuserbytoken/user", APiTokenData);
            if (getUser.data?.user) {
                setUser(getUser.data?.user)
            }
            console.log(getUser.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (BusSeatBlock) {
            setBusSeatBlock(BusSeatBlock);
        }
    }, [BusSeatBlock])

    // console.log(initialValues);

    // const getPaymentData = (data) => {
    //     console.log(data);
    //     // return http.post('/paytm_payments/payment').then(response=>response).catch(err=>console.log(err))
    //     return fetch(`` + baseUrl + `paytm_payments/payment`, {
    //         method: "POST",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             "x-access-token": process.env.REACT_APP_API_KEY,
    //         },
    //         body: JSON.stringify(data)
    //     }).then(response => { response.json(); console.log(response) }).catch(err => console.log(err))
    // }

    const completePayment = async (e) => {
        try {
            e.preventDefault();
            setError(false);
            setLoad(false);
            setSeatBlockError(false);
            setSeatBlockErrorData('');
            const OrangeData = {
                "verifyCall": getBusSeatBlock.verifyCall,
                "ReferenceNumber": getBusSeatBlock.ReferenceNumber,
                "PassengerName": getBusSeatBlock.PassengerName,
                "SeatNames": getBusSeatBlock.SeatNames,
                "Email": getBusSeatBlock.Email,
                "Phone": getBusSeatBlock.Phone,
                "PickupID": Number(getBusSeatBlock.PickupID),
                "PayableAmount": getBusSeatBlock.PayableAmount,
                "TotalPassengers": getBusSeatBlock.TotalPassengers,
            };
            console.log(location.state.busData.TRAVELLSNAME);
            //Blocking Seat
            console.log(getBusSeatBlock);
            let book = 0;
            let Response = '';
            let BlockID = '';
            if (location.state.busData.TRAVELLSNAME === "Orange Travels") {
                const seatBlockResponse = await httpCommon.post('busbooking/orangeapis/BlockSeatV2', OrangeData);
                if (seatBlockResponse?.data?.data[0]?.BlockID === 0) {
                    setSeatBlockError(true);
                    setLoad(true);
                    setSeatBlockErrorData(seatBlockResponse?.data?.data[0]?.Message);
                } else {
                    book = 1;
                    Response = seatBlockResponse?.data?.data[0];
                    BlockID = seatBlockResponse?.data?.data[0]?.Message;
                }
            }
            if (location.state.busData.TRAVELLSNAME === "VRL Travels") {
                const seatBlockResponse = await httpCommon.post('busbooking/vrlapis/BlockSeatV2', OrangeData);
                if (seatBlockResponse?.data?.data[0]?.BlockID === 0) {
                    setSeatBlockError(true);
                    setLoad(true);
                    setSeatBlockErrorData(seatBlockResponse?.data?.data[0]?.Message);
                } else {
                    book = 1;
                    Response = seatBlockResponse?.data?.data[0];
                    BlockID = seatBlockResponse?.data?.data[0]?.Message;
                }
            }

            if (book === 1) {
                const busbookingData = await getBookingData();
                let payMentData = {
                    purpose: 'Bus Booking',
                    amount: getPriceWithGST,
                    send_email: true,
                    buyer_name: getBusSeatBlock.PassengerName,
                    email: getBusSeatBlock.Email,
                    phone: getBusSeatBlock.Phone,
                    user_id: getUserData?.user_id,
                }
                const data = {
                    service_provider: location.state.busData.TRAVELLSNAME,
                    BlockID: BlockID,
                    bus_booking_request: JSON.stringify(BusSeatBook),
                    busbookingData: JSON.stringify(busbookingData),
                    bus_request: JSON.stringify(location.state),
                    block_request: JSON.stringify(OrangeData),
                    block_response: JSON.stringify(Response),
                    user_id: getUserData?.user_id,
                    created_by: getUserData?.user_id,
                    payMentData : JSON.stringify(payMentData),
                }
                const BlockSeat = await httpCommon.post('/busseatbooking/blockedSeat', data);
                payMentData.redirect_url = await baseUrl + '/paytm_payments/instacallBack/' + BlockSeat.data.bus_block_id;
                const paymentLink = await httpCommon.post('/paytm_payments/instaPayment', payMentData);
                console.log(paymentLink.data);
                window.location.href = paymentLink.data;
            }

        } catch (error) {
            setError(true);
            setLoad(true);
            console.log(error);
        }
    }

    async function getBookingData() {
        // let journeyData = '';
        // if (children?.SeatAvailability?.journeyData) {
        //     journeyData = children.SeatAvailability.journeyData;
        // }
        let data = {
            service_provider: getData.busData.TRAVELLSNAME,
            journey_id: getData.busData.JOURNEYID,
            coach_id: getData.busData.COACHID,
            from_city_id: getData.busData.USER_SOURCE_ID,
            to_city_id: getData.busData.USER_DESTINATION_ID,
            from_city_name: getData.busData.USER_SOURCE,
            to_city_name: getData.busData.USER_DESTINATION,
            boarding_point: getData.SELECTEDBORDINGPOINT.STOPNAME,
            droping_point: getData.SELECTEDDROPINGPOINT.STOPNAME,
            boarding_time: getData.SELECTEDBORDINGPOINT.STOPDATE + ", " + getData.SELECTEDBORDINGPOINT.STOPTIME,
            droping_time: getData.SELECTEDDROPINGPOINT.STOPDATE + ", " + getData.SELECTEDDROPINGPOINT.STOPTIME,
            journey_date: getData.getJourneyDate,
            user_phone: getData.USERPHONE,
            user_email: getData.USEREMAIL,
            user_id: getUserData?.user_id,
            booking_status: 'Pending',
            booking_pnr: "",
            total_fare: getPriceWithGST,
            payment_type: "Online",
            payment_status: "Pending",
            created_by: 0,
            journey_data: children?.getJourneyDate,
            busbookingpassengers: getData.USERDETAILS,
            selectedSeats: getData.SELECTEDSEATS,
            ReferenceNumber: getData.busData.REFERENCENUMBER
        }
        return data;
    }

    // function completePayment(e) {
    //     e.preventDefault();
    //     //add booking and passenger data
    //     setError(false);
    //     setLoad(false);
    //     let journeyData = '';
    //     if (children.SeatAvailability.journeyData) {
    //         journeyData = children.SeatAvailability.journeyData;
    //     }
    //     // let data = {
    //     //     service_provider: getData.busData.TRAVELLSNAME,
    //     //     journey_id: getData.busData.JOURNEYID,
    //     //     coach_id: getData.busData.COACHID,
    //     //     from_city_id: getData.busData.USER_SOURCE_ID,
    //     //     to_city_id: getData.busData.USER_DESTINATION_ID,
    //     //     from_city_name: getData.busData.USER_SOURCE,
    //     //     to_city_name: getData.busData.USER_DESTINATION,
    //     //     boarding_point: getData.SELECTEDBORDINGPOINT.STOPNAME,
    //     //     droping_point: getData.SELECTEDDROPINGPOINT.STOPNAME,
    //     //     boarding_time: getData.SELECTEDBORDINGPOINT.STOPDATE + ", " + getData.SELECTEDBORDINGPOINT.STOPTIME,
    //     //     droping_time: getData.SELECTEDDROPINGPOINT.STOPDATE + ", " + getData.SELECTEDDROPINGPOINT.STOPTIME,
    //     //     journey_date: getData.getJourneyDate,
    //     //     user_phone: getData.USERPHONE,
    //     //     user_email: getData.USEREMAIL,
    //     //     user_id: 0,
    //     //     booking_status: 'Pending',
    //     //     booking_pnr: "",
    //     //     total_fare: getPriceWithGST,
    //     //     payment_type: "Online",
    //     //     payment_status: "Pending",
    //     //     created_by: 0,
    //     //     journey_data: journeyData,
    //     //     busbookingpassengers: getData.USERDETAILS,
    //     //     selectedSeats: getData.SELECTEDSEATS,
    //     //     ReferenceNumber: getData.busData.REFERENCENUMBER
    //     // }

    //     // httpCommon.post("/busseatbooking/creatbooking", data)
    //     //     .then((Response) => {
    //     //         console.log(Response.data);
    //     //         getPaymentData({ amount: getPriceWithGST, email: getData.USEREMAIL, phone: getData.USERPHONE, bus_booking_id: Response.data.bus_booking_id, user_id: Response.data.user_id, payment_id: 1 }).then(response => {
    //     //             console.log(response);
    //     //             var information = {
    //     //                 action: "https://securegw-stage.paytm.in/theia/processTransaction",
    //     //                 params: response,
    //     //             }
    //     //             post(information);
    //     //         }).catch((error) => {
    //     //             console.log(error);
    //     //             setLoad(true);
    //     //             setError(true);
    //     //         })
    //     //     })
    //     //     .catch((error) => {
    //     //         console.log(error);
    //     //         setLoad(true);
    //     //         setError(true);
    //     // })

    // }

    return (
        <>
            {/* Page content starts here*/}
            <div className="page-content header-clear">
                <div className="content mb-2">
                    <div className="timeline-body timeline-body-center bg-white pb-1 pt-0">
                        <div className="timeline-item mt-2">
                            <div
                                className="timeline-item-content rounded-s shadow-l mt-1"
                                style={{ padding: 15 }}
                            >
                                <div className="col-12 mb-0">
                                    <h5 className="font-16 text-center" style={{ color: '#ff6600' }}>{getData.busData && getData.busData.TRAVELLSNAME && getData.busData.TRAVELLSNAME}</h5>
                                </div>
                                <div className=" col-12 mb-2 divider bg-gray-dark"></div>
                                <div className="accordion" id="accordion-1">
                                    <div className="mb-0">
                                        <button
                                            className="btn accordion-btn border-0 color-theme mr-1 p-1"
                                            data-toggle="collapse"
                                            data-target="#collapse2"
                                        >
                                            <i className="fa fa-bus mr-2 font-18" aria-hidden="true" />
                                            {getData.getFromCityName && getData.getFromCityName} &nbsp;
                                            <i className="fa fa-arrow-right" aria-hidden="true" />&nbsp;
                                            <i className="fa fa-chevron-down font-10 accordion-icon" />&nbsp;
                                            {getData.getToCityName && getData.getToCityName}
                                            <span className="ml-5">(₹{getPriceWithGST}/-)</span>
                                        </button>
                                        <div
                                            id="collapse2"
                                            className="collapse"
                                            data-parent="#accordion-1"
                                        >
                                            <hr className="mb-0" />
                                            <div className="py-2 pl-3 pr-3">
                                                <p className="mb-0 font-13">Boarding point</p>
                                                <p className="mb-1 font-10 row" style={{ color: "#ff6600" }}>
                                                    <span className="col-9 font-weight-bold">{getBoardingPoint.STOPNAME && getBoardingPoint.STOPNAME}</span>
                                                    <span className="col-3 font-weight-bold text-center">{getBoardingPoint.STOPDATE && getBoardingPoint.STOPDATE}, {getBoardingPoint.STOPTIME && getBoardingPoint.STOPTIME}</span>
                                                </p>
                                                <p className="mb-0 font-13">Dropping point</p>
                                                <p className="mb-0 font-10 row" style={{ color: "#ff6600" }}>
                                                    <span className="col-9 font-weight-bold">{getDropingPoint.STOPNAME && getDropingPoint.STOPNAME}</span>
                                                    <span className="col-3 font-weight-bold text-center">{getDropingPoint.STOPDATE && getDropingPoint.STOPDATE}, {getDropingPoint.STOPTIME && getDropingPoint.STOPTIME}</span>
                                                </p>
                                                <hr className="mb-0 " />
                                                <p className="font-14 mt-1 mb-0 text-center text-dark">
                                                    Travellers
                                                </p>
                                                <p className="font-13 mt-1 mb-2">
                                                    <u>Name</u>
                                                    <span className="float-right">
                                                        <u>Seat.No</u>
                                                    </span>
                                                </p>
                                                {getPassengers?.map && getPassengers.map((pasengers, i) => {
                                                    return <p key={i} className="mb-1" style={{ color: "#ff6600" }}>
                                                        {pasengers.NAME && pasengers.NAME}, {pasengers.AGE && pasengers.AGE}, {pasengers.AGE && pasengers.GENDER}
                                                        <span className="float-right">
                                                            {getSelectedSeats.filter((data) => data.SEATID == pasengers.SEATID)?.map && getSelectedSeats.filter((data) => data.SEATID == pasengers.SEATID).map((ss, j) => {
                                                                return <b key={j}>{ss.SEATNAME}</b>
                                                            })}
                                                        </span>
                                                    </p>
                                                })}
                                                <hr className="mb-2 " />
                                                <p className="font-14 mt-1 mb-0 text-center text-dark">
                                                    Price
                                                </p>
                                                <p className="mb-1" style={{ color: "#ff6600" }}>
                                                    Total Fare<span className="float-right font-weight-bold">{getPrice} /-</span>
                                                </p>
                                                {/* <p className="mb-1" style={{ color: "#ff6600" }}>
                                                GST<span className="float-right font-weight-bold">{getGST} /-</span>
                                            </p>
                                            <p className="mb-1" style={{ color: "#ff6600" }}>
                                                Assured Charge<span className="float-right font-weight-bold">₹{getPriceWithGST} /-</span>
                                            </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <h6 className="mt-1 text-dark">Payment options</h6> */}
                    {/* <div className="timeline-body timeline-body-center bg-white pb-1 pt-0">
                        <div className="timeline-item mt-2">
                            <div
                                className="timeline-item-content rounded-s shadow-l mt-1"
                                style={{ padding: 15 }}
                            >
                                <p className="text-dark mb-1" style={{ letterSpacing: 1 }}>
                                    <b>Pay With Paytm</b>
                                </p>
                                <img
                                    src="assets/images/travel/paytm.png"
                                    alt=""
                                    width="50px"
                                    className="border rounded"
                                />
                            </div>
                        </div>
                    </div> */}
                    {getLoad ?
                        <button
                            type="button"
                            onClick={(e) => completePayment(e)}
                            className="btn btn-xs rounded-sm font-500 mt-2"
                            style={{ backgroundColor: "#ff6600", width: '100%' }}
                        >
                            Pay Now
                        </button>
                        :
                        <button
                            type="button"
                            disabled={true}
                            className="btn btn-xs rounded-sm font-500 mt-2"
                            style={{ backgroundColor: "#ff6600", width: '100%' }}
                        >
                            <span className="spinner-border spinner-border-sm"></span> booking ...
                        </button>
                    }
                    {getError &&
                        <p className="text-center text-danger mt-2">something went wrong try after sometime!</p>
                    }
                    {seatBlockError &&
                        <p className="text-center text-danger mt-2">{seatBlockErrorData}!</p>
                    }
                </div>
            </div>
        </>
    )
}

export { GetPayment };