import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavTwo } from "../navs/topnavtwo";
import { BusBookingContent } from "./busbookingcontent";

const BusBooking = () => {
    return(
        <>
            <TopNavTwo children={"Bus Tickets"}/>
            <FooterNav children={"main"}/>
            <BusBookingContent/>
            <FooterScript/>
        </>
    )
}

export {BusBooking};