// import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavThree } from "../navs/topnavthree";
import { GetPassengerData } from "./getpassengerdata";
// import { TopNavTwo } from "../navs/topnavtwo";

const AddPassengerDetails = () => {
    return(
        <>
            <TopNavThree />
            {/* <FooterNav children={"main"}/> */}
            <GetPassengerData />
            <FooterScript/>
        </>
    )
}

export {AddPassengerDetails};