import { Link } from "react-router-dom";
const MainPageContent = () => {
    return(
        <div className="page-content header-clear">
            {/* Travel booking starts */}
            <div className="content text-center travel-title">
                <h4 className="travel-h4">Travel Bookings</h4>
            </div>
            <div className="container-fluid pr-1 pl-1">
                <div className="card rounded-m shadow-xl border-1 p-3 mb-2">
                    <div className="row text-center mb-2">
                        <div className="col-3">
                            <div>
                                <Link to={"/busbooking"}>
                                    <div style={{ lineHeight: 12 }}>
                                        <img
                                        src="assets/images/travel/bus.png"
                                        width={40}
                                        className=" mx-auto mb-n4 "
                                        />
                                    </div>
                                    <br />
                                    <span className="d-block pt-1 font-12 color-theme mt-2 font-weight-bold mt-2 font-weight-bold">BUS</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-3">
                            <div>
                                <Link to={"/trainbooking"}>
                                    <img
                                        src="assets/images/travel/train1.png"
                                        width={40}
                                        className=" mx-auto mb-n4 "
                                    />
                                    <br />
                                    <span className="d-block pt-1 font-12 color-theme mt-2 font-weight-bold">TRAINS</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-3">
                            <div>
                                <Link to={"/flightbooking"}>
                                    <img
                                        src="assets/images/travel/flight.png"
                                        width={40}
                                        className=" mx-auto mb-n4 "
                                    />
                                    <br />
                                    <span className="d-block pt-1 font-12 color-theme mt-2 font-weight-bold">FLIGHTS</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-3">
                            <div>
                                <Link to={"#"}>
                                    <img
                                        src="assets/images/travel/resort.png"
                                        width={40}
                                        className=" mx-auto mb-n4 "
                                    />
                                    <br />
                                    <span className="d-block pt-1 font-12 color-theme mt-2 font-weight-bold">HOTELS</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center " style={{ marginBottom: 5 }}>
                        <div className="col-3">
                            <div className="">
                                <Link to={"#"}>
                                    <img
                                        src="assets/images/travel/car1.png"
                                        width={40}
                                        className=" mx-auto mb-n4 "
                                    />
                                    <br />
                                    <span className="d-block pt-1 font-12 color-theme mt-2 font-weight-bold">RENTAL</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="">
                                <a href="https://yesbususercabs.cafemobility.com/ride/small/MQ==">
                                    <img
                                        src="assets/images/travel/car1.png"
                                        width={40}
                                        className=" mx-auto mb-n4 "
                                    />
                                    <br />
                                    <span className="d-block pt-1 font-12 color-theme mt-2 font-weight-bold">CAB BOOKING</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-3">
                            <div>
                                <Link to="#">
                                    <img
                                        src="assets/images/travel/right.png"
                                        width={40}
                                        className=" mx-auto mb-n4 "
                                    />
                                    <br />
                                    <span className="d-block pt-1 font-12 color-theme mt-2 font-weight-bold">View All</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Travel booking ends */}
            {/* Insurance starts */}
            {/* <div className="card px-2 rounded-m shadow-xl bg-white mx-1 text-center border-1">
                <h5 className="font-17 pt-3 pl-1 text-left">Insurance</h5>
                <div className="row text-center d-flex Insurance-content">
                    <div className="col-3 p-0">
                        <Link to="#">
                            <img
                                src="assets/images/travel/motorbike.png"
                                width={55}
                                className=" mx-auto mb-n5 "
                            />
                            <br />
                            <span className="d-block pt-2 font-10 color-theme mt-2 font-weight-bold mt-2 font-weight-bold">
                                Bike Insurance
                            </span>
                        </Link>
                    </div>
                    <div className="col-3 p-0">
                        <Link to="#">
                            <img
                                src="assets/images/travel/car4.png"
                                width={55}
                                className=" mx-auto mb-n5 "
                            />
                            <br />
                            <span className="d-block pt-2 font-10 color-theme mt-2 font-weight-bold">
                                Car Insurance
                            </span>
                        </Link>
                    </div>
                    <div className="col-3 p-0">
                        <Link to="#">
                            <img
                                src="assets/images/travel/health1.png"
                                width={55}
                                className=" mx-auto mb-n5 "
                            />
                            <br />
                            <span className="d-block pt-2 font-10 color-theme mt-2 font-weight-bold">
                                Health Insurance
                            </span>
                        </Link>
                    </div>
                    <div className="col-3 p-0">
                        <Link to="#">
                            <img
                                src="assets/images/travel/family.png"
                                width={55}
                                className=" mx-auto mb-n5 "
                            />
                            <br />
                            <span className="d-block pt-2 font-10 color-theme mt-2 font-weight-bold">
                                Life Insurance
                            </span>
                        </Link>
                    </div>
                </div>
                <p className="font-10 pt-1 mb-2 insurance-botm">
                    Renew your Bike Policy at just 1rs/day
                </p>
            </div> */}
            {/* Insurance ends */}
            {/* Offers box starts */}
            <div className="content rounded pt-2 ofr-box">
                <Link to={"/offers"}>
                    <h5>
                        <img
                        src="assets/images/offer/discounte.png"
                        style={{ width: 20 }}
                        alt=""
                        />
                        <span className="m-1">OFFERS</span>
                        <span
                        className="float-right font-weight-normal border border-dark rounded pr-2 pl-2 mt-1"
                        style={{ fontSize: 13, backgroundColor: "#ff6600bb" }}
                        >
                            View All
                        </span>
                    </h5>
                </Link>
                <span>Get best deals with great discounts and offers.</span>
            </div>
            {/* Offers box ends */}
            {/* Offers slider starts */}
            <div className="double-slider owl-carousel owl-no-dots pt-2 ofr-sldr">
                <div className="item">
                    <div
                        data-card-height={185}
                        style={{ backgroundImage: 'url("assets/images/offer/hotel.jpg")' }}
                        className="card rounded-m"
                    >
                        <div className="card-bottom text-center mb-1">
                            <h2 className="color-white text-uppercase font-900" />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div
                        data-card-height={185}
                        style={{ backgroundImage: 'url("assets/images/offer/bus2.jpg")' }}
                        className="card rounded-m"
                    >
                        <div className="card-bottom text-center mb-1">
                            <h2 className="color-white text-uppercase font-900" />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div
                        data-card-height={185}
                        style={{ backgroundImage: 'url("assets/images/offer/train3.jpg")' }}
                        className="card rounded-m"
                    >
                        <div className="card-bottom text-center mb-1">
                            <h2 className="color-white text-uppercase font-900" />
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div
                        data-card-height={185}
                        style={{ backgroundImage: 'url("assets/images/offer/cab.jpg")' }}
                        className="card rounded-m"
                    >
                        <div className="card-bottom text-center mb-1">
                            <h2 className="color-white text-uppercase font-900" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Offers slider ends */}
        </div>
    )
}

export {MainPageContent};