import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavTwo } from "../navs/topnavtwo";
import { NotificationsContent } from "./notificationscontent";

const Notifications = () => {
    return(
        <>
            <TopNavTwo children={"Notifications"}/>
            <FooterNav children={"main"}/>
            <NotificationsContent/>
            <FooterScript/>
        </>
    )
}

export {Notifications};