import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavTwo } from "../navs/topnavtwo";
import { TrainBookingContent } from "./trainbookingcontent";

const TrainBooking = () => {
    return(
        <>
            <TopNavTwo children={"Train Tickets"}/>
            <FooterNav children={"main"}/>
            <TrainBookingContent/>
            <FooterScript/>
        </>
    )
}

export {TrainBooking};