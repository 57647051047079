import { createSlice } from '@reduxjs/toolkit';
import httpCommon from '../../http-common';
// utils
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    vrlSources: [],
    orangeSources:[],
    srsSources: [],
};

const slice = createSlice({
    name: 'vrlSources',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET VRL SOURCES
        getVrlSourceSuccess(state, action) {
            state.isLoading = false;
            state.vrlSources = action.payload;
        },

        // GET Orange SOURCES
        getOrangeSourceSuccess(state, action) {
            state.isLoading = false;
            state.orangeSources = action.payload;
        },

        // GET SRS SOURCES
        getSRSSourceSuccess(state, action) {
            state.isLoading = false;
            state.srsSources = action.payload;
        },
        
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getVrlSources() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const OrangeData = {
                "verifyCall" : process.env.REACT_APP_VRLVerifyCall
            };
            const response = await httpCommon.post('busbooking/vrlapis/GetSources', OrangeData);
            let data = [];
            response.data.data && response.data.data.ITSSources?.map && response.data.data.ITSSources.map(async (Cities, index) => {
                const citiData =  {
                    'id' : Cities.CM_CityID,
                    'name' : Cities.CM_CityName
                }
                data.push(citiData);
            })
            dispatch(slice.actions.getVrlSourceSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOrangeSources() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const OrangeData = {
                "verifyCall" : process.env.REACT_APP_OrangeVerifyCall
            };
            const response = await httpCommon.post('busbooking/orangeapis/GetSources', OrangeData);
            let data = [];
            response.data.data && response.data.data.ITSSources?.map && response.data.data.ITSSources.map(async (Cities, index) => {
                const citiData =  {
                    'id' : Cities.CM_CityID,
                    'name' : Cities.CM_CityName
                }
                data.push(citiData);
            })
            dispatch(slice.actions.getOrangeSourceSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSRSSources() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const SrsReq = "cities";
            const response = await httpCommon.get('busbooking/srsapis/' + SrsReq);
            let data = [];
            response.data.result && response.data.result?.map && response.data.result.map((srsdata, index) => {
                if(index === 0){

                }else{
                    let id = '';
                    let CITYNAME = '';
                    srsdata?.map && srsdata.map((sourceData, index1) => {
                        if(index1 === 0){
                            id = sourceData;
                        }else if(index1 === 1){
                            CITYNAME = sourceData;
                        }
                    })
                    let p_data = {
                        'id' : id,
                        'name' : CITYNAME
                    }
                    data.push(p_data);
                }
            })
            dispatch(slice.actions.getSRSSourceSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}