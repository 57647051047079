import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavTwo } from "../navs/topnavtwo";
import { BookingContent } from "./bookingcontent";

const MyBookings = () => {
    return(
        <>
            <TopNavTwo children={"My Bookings"}/>
            <FooterNav children={"bookings"}/>
            <BookingContent />
            <FooterScript/>
        </>
    )
}

export {MyBookings};