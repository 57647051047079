import { Link } from "react-router-dom"

const TopNavTwo = (children) => {
    let data = children
    return(
        <div className="header header-fixed header-logo-app custom-header">
            <a className="header-title text-white" style={{fontWeight: '500!important', fontSize: '13px'}}>{data.children}</a>
            <Link to={"/main"} className="header-icon header-icon-1 text-white"><i className="fas fa-arrow-left" /></Link>
        </div>
    )
}

export {TopNavTwo};