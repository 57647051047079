import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import format from "date-fns/format";
import httpCommon from '../../http-common';
// utils
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    vrlBuses: [],
    orangeBuses:[],
    srsBuses: [],
};

const slice = createSlice({
    name: 'vrlSources',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET VRL BUSES
        getVrlBusSuccess(state, action) {
            state.isLoading = false;
            state.vrlBuses = action.payload;
        },

        // GET ORANGE BUSES
        getOrangeBusSuccess(state, action) {
            state.isLoading = false;
            state.orangeBuses = action.payload;
        },

        // GET SRS BUSES
        getSrsBusSuccess(state, action) {
            state.isLoading = false;
            state.srsBuses = action.payload;
        },
        
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

function BoardingAndDropingPoints(data) {
    const SPLITBOARDIN = data.split('#');
    let STOPPOINTS = [];    
    SPLITBOARDIN?.map && SPLITBOARDIN.map((getData) => {
        let pdata = {
            STOPNAME : '',
            STOPDATE : '',
            STOPTIME : '',
            STOPID:''
        };
        const fareSplit = getData.split('|');
        pdata.STOPNAME = fareSplit[1];
        pdata.STOPTIME = fareSplit[2];
        pdata.STOPID = fareSplit[0];
        STOPPOINTS.push(pdata);
    });
    return STOPPOINTS;
}

export function getVrlBuses(FromID, ToID, JourneyDate) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const vrlData = {
                "verifyCall" : process.env.REACT_APP_VRLVerifyCall,
                "FromID" : FromID,
                "ToID" : ToID,
                "JourneyDate" : JourneyDate
            };
            const response = await httpCommon.post('busbooking/vrlapis/GetAvailableRoutes', vrlData);
            console.log(response.data, vrlData);
            let data = [];
            response.data.data && response.data.data.AllRouteBusLists?.map && response.data.data.AllRouteBusLists.map((buses, index) => {
                // console.log(buses);
                if(buses.CompanyID !== 0) {
                    let Fare = [];
                    if(buses.AcSeatRate > 0){
                        Fare.push(buses.AcSeatRate);
                    }
                    if(buses.AcSleeperRate > 0){
                        Fare.push(buses.AcSleeperRate);
                    }
                    if(buses.AcSlumberRate > 0){
                        Fare.push(buses.AcSlumberRate);
                    }
                    if(buses.NonAcSeatRate > 0){
                        Fare.push(buses.NonAcSeatRate);
                    }
                    if(buses.NonAcSleeperRate > 0){
                        Fare.push(buses.NonAcSleeperRate);
                    }
                    if(buses.NonAcSlumberRate > 0){
                        Fare.push(buses.NonAcSlumberRate);
                    }
                    var date1 = new Date(buses.BookingDate);
                    var date2 = new Date(buses.ApproxArrival);
                    let busData ={
                        COMPANYID:buses.CompanyID,
                        COMPANYNAME:buses.CompanyName,
                        TRAVELLSNAME : 'VRL Travels',
                        JOURNEYNAME: buses.RouteName,
                        BOARDINGPOINTS:BoardingAndDropingPoints(buses.BoardingPoints),
                        BULK_DISCOUNT:'',
                        COACHNAME:buses.BusTypeName,
                        COACHID:'',
                        DROPINGPOINTS:BoardingAndDropingPoints(buses.DroppingPoints),                                
                        JOURNEYID : buses.RouteID,
                        JOURNEY_DESTINATION:'',
                        REFERENCENUMBER:buses.ReferenceNumber,
                        JOURNEY_DISTANCE: buses.Kilometer,
                        JOURNEY_DURATION: buses.CityTime24,
                        JOURNEY_SOURCE:'',                                    
                        NON_REFUND_JOURNEY:'',
                        SEATFARE:[],
                        SEATSAVALIABLE: buses.EmptySeats,
                        SEATSBOOKED:[],
                        SHOW_BOARDING_TIME: buses.BookingDate + " " + buses.CityTime,                                    
                        SHOW_DROPING_TIME: buses.ApproxArrival,
                        USER_DESTINATION: buses.ToCityName,
                        USER_DESTINATION_ID:buses.ToCityId,
                        USER_SOURCE:buses.FromCityName,
                        USER_SOURCE_ID: buses.FromCityId,
                        MINIMUMSEATPRICE: Math.min(...Fare),
                        BUSTYPE:buses.BusType,
                        BUSSEATTYPE:buses.BusType,
                        RouteAmenities:buses.RouteAmenities,
                    }
                    data.push(busData);
                }
            });
            dispatch(slice.actions.getVrlBusSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOrangeBuses(FromID, ToID, JourneyDate) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const OrangeData = {
                "verifyCall" : process.env.REACT_APP_OrangeVerifyCall,
                "FromID" : FromID,
                "ToID" : ToID,
                "JourneyDate" : JourneyDate
            };
            const response = await httpCommon.post('busbooking/orangeapis/GetAvailableRoutes', OrangeData);
            console.log(response.data, OrangeData);
            let data = [];
            response.data.data && response.data.data.AllRouteBusLists?.map && response.data.data.AllRouteBusLists.map((buses, index) => {
                // console.log(buses);
                if(buses.CompanyID !== 0) {
                    let Fare = [];
                    if(buses.AcSeatRate > 0){
                        Fare.push(buses.AcSeatRate);
                    }
                    if(buses.AcSleeperRate > 0){
                        Fare.push(buses.AcSleeperRate);
                    }
                    if(buses.AcSlumberRate > 0){
                        Fare.push(buses.AcSlumberRate);
                    }
                    if(buses.NonAcSeatRate > 0){
                        Fare.push(buses.NonAcSeatRate);
                    }
                    if(buses.NonAcSleeperRate > 0){
                        Fare.push(buses.NonAcSleeperRate);
                    }
                    if(buses.NonAcSlumberRate > 0){
                        Fare.push(buses.NonAcSlumberRate);
                    }
                    var date1 = new Date(buses.BookingDate);
                    var date2 = new Date(buses.ApproxArrival);
                    let busData ={
                        COMPANYID:buses.CompanyID,
                        COMPANYNAME:buses.CompanyName,
                        TRAVELLSNAME : 'Orange Travels',
                        JOURNEYNAME: buses.RouteName,
                        BOARDINGPOINTS:BoardingAndDropingPoints(buses.BoardingPoints),
                        BULK_DISCOUNT:'',
                        COACHNAME:buses.BusTypeName,
                        COACHID:'',
                        DROPINGPOINTS:BoardingAndDropingPoints(buses.DroppingPoints),                                
                        JOURNEYID : buses.RouteID,
                        JOURNEY_DESTINATION:'',
                        REFERENCENUMBER:buses.ReferenceNumber,
                        JOURNEY_DISTANCE: buses.Kilometer,
                        JOURNEY_DURATION: buses.CityTime24,
                        JOURNEY_SOURCE:'',                                    
                        NON_REFUND_JOURNEY:'',
                        SEATFARE:[],
                        SEATSAVALIABLE: buses.EmptySeats,
                        SEATSBOOKED:[],
                        SHOW_BOARDING_TIME: buses.BookingDate + " " + buses.CityTime,                                    
                        SHOW_DROPING_TIME: buses.ApproxArrival,
                        USER_DESTINATION: buses.ToCityName,
                        USER_DESTINATION_ID:buses.ToCityId,
                        USER_SOURCE:buses.FromCityName,
                        USER_SOURCE_ID: buses.FromCityId,
                        MINIMUMSEATPRICE: Math.min(...Fare),
                        BUSTYPE:buses.BusType,
                        BUSSEATTYPE:buses.BusType,
                        RouteAmenities:buses.RouteAmenities,
                    }
                    data.push(busData);
                }
            });
            dispatch(slice.actions.getOrangeBusSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSrsBuses(FromID, ToID, JourneyDate) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const SrsReq = `schedules`;
            const reqdata = {
                query : `/${FromID}/${ToID}/${JourneyDate}`
            }
            const response = await httpCommon.post('busbooking/srsapis/' + SrsReq, reqdata);
            // console.log(response.data);
            let data = [];
            response.data.result && response.data.result?.map && response.data.result.map((buses, index) => {
                // console.log(buses);
                if(index !== 0) {
                    console.log(buses);
                    let Fare = [];
                    if(buses.AcSeatRate > 0){
                        Fare.push(buses.AcSeatRate);
                    }
                    if(buses.AcSleeperRate > 0){
                        Fare.push(buses.AcSleeperRate);
                    }
                    if(buses.AcSlumberRate > 0){
                        Fare.push(buses.AcSlumberRate);
                    }
                    if(buses.NonAcSeatRate > 0){
                        Fare.push(buses.NonAcSeatRate);
                    }
                    if(buses.NonAcSleeperRate > 0){
                        Fare.push(buses.NonAcSleeperRate);
                    }
                    if(buses.NonAcSlumberRate > 0){
                        Fare.push(buses.NonAcSlumberRate);
                    }
                    var date1 = new Date(buses.BookingDate);
                    var date2 = new Date(buses.ApproxArrival);
                    let busData ={
                        COMPANYID:buses[0],
                        COMPANYNAME:buses[3],
                        TRAVELLSNAME : 'SRA Travels',
                        JOURNEYNAME: buses[1],
                        BOARDINGPOINTS:[],
                        BULK_DISCOUNT:'',
                        COACHNAME:buses[8],
                        COACHID:'',
                        DROPINGPOINTS:[],                                
                        JOURNEYID : buses[6],
                        JOURNEY_DESTINATION:'',
                        REFERENCENUMBER:'',
                        JOURNEY_DISTANCE: buses.Kilometer,
                        JOURNEY_DURATION: buses.CityTime24,
                        JOURNEY_SOURCE:'',                                    
                        NON_REFUND_JOURNEY:'',
                        SEATFARE:[],
                        SEATSAVALIABLE: buses.EmptySeats,
                        SEATSBOOKED:[],
                        SHOW_BOARDING_TIME: buses.BookingDate + " " + buses.CityTime,                                    
                        SHOW_DROPING_TIME: buses.ApproxArrival,
                        USER_DESTINATION: buses.ToCityName,
                        USER_DESTINATION_ID:buses.ToCityId,
                        USER_SOURCE:buses.FromCityName,
                        USER_SOURCE_ID: buses.FromCityId,
                        MINIMUMSEATPRICE: Math.min(...Fare),
                        BUSTYPE:buses.BusType,
                        BUSSEATTYPE:buses.BusType,
                        RouteAmenities:buses[21],
                    }
                    data.push(busData);
                }
            });
            dispatch(slice.actions.getSrsBusSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}