import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import './tabStyle.css'

function getMinRowValue(seats){
    // console.log(SEATFARE);
    let getFare = [];
    seats?.map && seats.map((fare) => {
        getFare.push((fare.ROW))
    })
    if(Math.min(...getFare) === Infinity){
        return (0);
    }else{
        return (Math.min(...getFare));
    }
}

function getMaxRowValue(seats){
    // console.log(SEATFARE);
    let getFare = [];
    seats?.map && seats.map((fare) => {
        getFare.push((fare.ROW))
    })
    if(Math.min(...getFare) === Infinity){
        return (0);
    }else{
        return (Math.max(...getFare));
    }
}

function getMaxColumnValue(seats){
    // console.log(SEATFARE);
    let getFare = [];
    seats?.map && seats.map((fare) => {
        getFare.push((fare.COLUMN))
    })
    if(Math.min(...getFare) === Infinity){
        return (0);
    }else{
        return (Math.max(...getFare));
    }
}

const BusCaochPositionView = (coachPosition) => {
    const [getLowerCoachPosition, setLowerCoachPosition] = useState([]);
    const [getUpperCoachPosition, setUpperCoachPosition] = useState([]);
    const [coachType, setCOachType] = useState('Lower');
    const [getSelectedSeats, setSelectedSeats] = useState([]);

    useEffect(() => {
        if(coachPosition.coachPosition.length > 0){
            var coachP = coachPosition.coachPosition;
            var LowerCoach = coachP.filter((lower) => lower.UPLOWBEARTH === "LB");
            setLowerCoachPosition(LowerCoach);
            var UpperCoach = coachP.filter((lower) => lower.UPLOWBEARTH === "UB");
            setUpperCoachPosition(UpperCoach);

            coachPosition.coachPosition?.map && coachPosition.coachPosition.map((cpdata) => {
                // console.log(cpdata);
                if(cpdata.UPLOWBEARTH === 'UB'){
                    setCOachType("Upper");
                }
            })
        }
    }, [coachPosition]);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(!location.state){
            window.location.reload();
            navigate('/busbooking');
        }
    }, [])

    function SelectSeat(e, SEATS, SeatType, id){

        var ele = document.getElementById(id);
        if(SeatType == 'Berth'){
            if(e.target.checked == true){
                ele.style.backgroundImage = 'url("assets/images/busseats/selected-berth.png")';
                setSelectedSeats(oldData => [...oldData, SEATS]);
            }else{
                ele.style.backgroundImage = 'url("assets/images/busseats/general-berth.png")';
                var ssf =  getSelectedSeats.filter((data) => data.SEATID !== SEATS.SEATID);
                setSelectedSeats(ssf);
            }
        }else{
            if(e.target.checked == true){
                ele.style.backgroundImage = 'url("assets/images/busseats/selected-seat.png")';
                setSelectedSeats(oldData => [...oldData, SEATS]);
            }else{
                ele.style.backgroundImage = 'url("assets/images/busseats/general-seat.png")';
                var ssf =  getSelectedSeats.filter((data) => data.SEATID !== SEATS.SEATID);
                setSelectedSeats(ssf);
            }
        }
    }

    function getLower(){

        var minRow = getMinRowValue(getLowerCoachPosition);
        var maxRow = getMaxRowValue(getLowerCoachPosition);
        var maxColumn = getMaxColumnValue(getLowerCoachPosition);
        var totalRows = maxRow - minRow + 1;
        // console.log(totalRows);

        return(
            <>  
                    <div className="autobus rounded m-auto">
                        <img
                            className="d-block ml-auto mr-3"
                            src="assets/images/travel/hands.png"
                            alt=""
                            width="55px"
                        />
                        <div className="fila">
                            <table className="table">
                                <tbody>
                                    {Array.from(Array(maxRow), (e, i) => {
                                        return (<tr key={i}>
                                                    {Array.from(Array(maxColumn), (a, j) => {
                                                        return (
                                                            <td key={j} className="m-auto text-center p-1">
                                                                {getLowerCoachPosition.filter((data) => data.COLUMN == j+1 && data.ROW == i+1).map((SEATS, l) => {
                                                                    return <div className="m-auto text-center" key={l}>
                                                                            {SEATS.AVAILABLE == 'N' ? 
                                                                                <>
                                                                                    {SEATS.BOOKINGGENDER == 'FEMALE' ?
                                                                                        <>
                                                                                            {SEATS.SEATTYPE == 'Berth' ? 
                                                                                                <>
                                                                                                    <label className="l-berth seat-bg" style={{backgroundImage:'url("assets/images/busseats/female-booked-berth.png")'}}>
                                                                                                        {SEATS.SEATNAME && SEATS.SEATNAME}
                                                                                                    </label>
                                                                                                </>
                                                                                            :
                                                                                                <>
                                                                                                    <label className="l-seater seat-bg" style={{backgroundImage:'url("assets/images/busseats/female-booked-seat.png")'}}>
                                                                                                        {SEATS.SEATNAME && SEATS.SEATNAME}
                                                                                                    </label>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    :
                                                                                        <>
                                                                                            {SEATS.SEATTYPE == 'Berth' ? 
                                                                                                <>
                                                                                                    <label style={{backgroundImage:'url("assets/images/busseats/general-booked-berth.png")'}} className="l-berth seat-bg">
                                                                                                        {SEATS.SEATNAME && SEATS.SEATNAME}
                                                                                                    </label>
                                                                                                </>
                                                                                            :
                                                                                                <>
                                                                                                    <label style={{backgroundImage:'url("assets/images/busseats/general-booked-seat.png")'}} className="l-seater seat-bg">
                                                                                                        <b>{SEATS.SEATNAME && SEATS.SEATNAME}</b>
                                                                                                    </label>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            :
                                                                                <>
                                                                                    {SEATS.SEATTYPE == 'Berth' ? 
                                                                                        <>  
                                                                                            <input style={{display:'none'}} onChange={(e) => SelectSeat(e, SEATS, 'Berth', "seat_label_"+SEATS.SEATID)} type="checkbox" id={"seat_"+SEATS.SEATID}/>
                                                                                            <label id={"seat_label_"+SEATS.SEATID} htmlFor={"seat_"+SEATS.SEATID} style={{backgroundImage:'url("assets/images/busseats/general-berth.png")'}} className="l-berth seat-bg">
                                                                                                <b>{SEATS.SEATNAME && SEATS.SEATNAME}</b>
                                                                                            </label>
                                                                                        </>
                                                                                    :
                                                                                        <>
                                                                                            <input style={{display:'none'}} onChange={(e) => SelectSeat(e, SEATS, 'Seat', "seat_label_"+SEATS.SEATID)} type="checkbox" id={"seat_"+SEATS.SEATID}/>
                                                                                            <label id={"seat_label_"+SEATS.SEATID} htmlFor={"seat_"+SEATS.SEATID} style={{backgroundImage:'url("assets/images/busseats/general-seat.png")'}} className="l-seater seat-bg m-auto">
                                                                                                <b>{SEATS.SEATNAME && SEATS.SEATNAME}</b>
                                                                                            </label>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                    </div>
                                                                })}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
            </>
        )
    }   

    function getUpper(){
        var minRow = getMinRowValue(getUpperCoachPosition);
        var maxRow = getMaxRowValue(getUpperCoachPosition);

        var maxColumn = getMaxColumnValue(getUpperCoachPosition);
        var totalRows = maxRow - minRow + 1;
        // console.log(getUpperCoachPosition);


        return(
            <>  
                    <div className="autobus rounded m-auto">
                        <img
                            style={{visibility:'hidden'}}
                            className="d-block ml-auto mr-3"
                            src="assets/images/travel/hands.png"
                            alt=""
                            width="55px"
                        />
                        <div className="fila">
                            <table className="table">
                                <tbody>
                                    {Array.from(Array(maxRow), (e, i) => {
                                        return (<tr key={i}>
                                                    {Array.from(Array(maxColumn), (a, j) => {
                                                        return (
                                                            <td key={j} className="m-auto text-center">
                                                                {getUpperCoachPosition.filter((data) => data.COLUMN == j+1 && data.ROW == i+1).map((SEATS, l) => {
                                                                    return <div className="m-auto text-center" key={l}>
                                                                            {SEATS.AVAILABLE == 'N' ? 
                                                                                <>
                                                                                    {SEATS.BOOKINGGENDER == 'FEMALE' ?
                                                                                        <>
                                                                                            {SEATS.SEATTYPE == 'Berth' ? 
                                                                                                <>
                                                                                                    <label className="l-berth seat-bg" style={{backgroundImage:'url("assets/images/busseats/female-booked-berth.png")'}}>
                                                                                                        {SEATS.SEATNAME && SEATS.SEATNAME}
                                                                                                    </label>
                                                                                                </>
                                                                                            :
                                                                                                <>
                                                                                                    <label className="l-seater seat-bg" style={{backgroundImage:'url("assets/images/busseats/female-booked-seat.png")'}}>
                                                                                                        {SEATS.SEATNAME && SEATS.SEATNAME}
                                                                                                    </label>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    :
                                                                                        <>
                                                                                            {SEATS.SEATTYPE == 'Berth' ? 
                                                                                                <>
                                                                                                    <label style={{backgroundImage:'url("assets/images/busseats/general-booked-berth.png")'}} className="l-berth seat-bg">
                                                                                                        {SEATS.SEATNAME && SEATS.SEATNAME}
                                                                                                    </label>
                                                                                                </>
                                                                                            :
                                                                                                <>
                                                                                                    <label style={{backgroundImage:'url("assets/images/busseats/general-booked-seat.png")'}} className="l-seater seat-bg">
                                                                                                        <b>{SEATS.SEATNAME && SEATS.SEATNAME}</b>
                                                                                                    </label>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            :
                                                                                <>
                                                                                    {SEATS.SEATTYPE == 'Berth' ? 
                                                                                        <>  
                                                                                            <input style={{display:'none'}} onChange={(e) => SelectSeat(e, SEATS, 'Berth', "seat_label_"+SEATS.SEATID)} type="checkbox" id={"seat_"+SEATS.SEATID}/>
                                                                                            <label id={"seat_label_"+SEATS.SEATID} htmlFor={"seat_"+SEATS.SEATID} style={{backgroundImage:'url("assets/images/busseats/general-berth.png")'}} className="l-berth seat-bg">
                                                                                                <b>{SEATS.SEATNAME && SEATS.SEATNAME}</b>
                                                                                            </label>
                                                                                        </>
                                                                                    :
                                                                                        <>
                                                                                            <input style={{display:'none'}} onChange={(e) => SelectSeat(e, SEATS, 'Seat', "seat_label_"+SEATS.SEATID)} type="checkbox" id={"seat_"+SEATS.SEATID}/>
                                                                                            <label id={"seat_label_"+SEATS.SEATID} htmlFor={"seat_"+SEATS.SEATID} style={{backgroundImage:'url("assets/images/busseats/general-seat.png")'}} className="l-seater seat-bg m-auto">
                                                                                                <b>{SEATS.SEATNAME && SEATS.SEATNAME}</b>
                                                                                            </label>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                    </div>
                                                                })}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
            </>
        )

    }

    function getPrice(SEATS){
        if(SEATS.length && SEATS.length > 0){
            var price = '0';
            SEATS.map((data) => {
                price = +price + +data.BASE_FAREWITH_GST_TAX;
            })
            return price;
        }else{
            return 0;
        }
    }

    function ProceedToBooking(e, data){
        e.preventDefault();
        data.redirectUrl = "buscoaschview";
        data.SELECTEDSEATS = getSelectedSeats;
        navigate('/selectbording', { state : data });
    }

    return(
        <>
            <div className="page-content header-clear">
                <div className="content mb-2">
                    {coachType === 'Upper' ? 
                        <>
                            <Tabs activeTab="1" className="mt-2" ulClassName="" activityClassName="bg-warning"  >
                                <Tab title="Lower" className="mr-3 tab-l font-weight-bold tab-50" style={{width: '50%',textAlign: 'center'}}>
                                    {/* // tab contents  */}
                                    <div className="mt-3">
                                        {getLower()}
                                    </div>
                                    {/* // ./ tab contents  */}
                                </Tab>
                                
                                <Tab title="Upper" className="mr-3 font-weight-bold tab-50">
                                    <div className="mt-3">
                                        {getUpper()}
                                    </div>
                                </Tab>
                                    
                            </Tabs>
                        </>
                        :
                        <>
                            <div className="mt-3">
                                {getLower()}
                            </div>
                        </>
                    }
                </div>  
            </div>
            <div id="footer-bar" className="bg-dark" style={{ height: "auto", minHeight: "35px !important" }}>
                <div className="col-4 m-auto text-white" style={{ borderRight: "1px solid white" }}>
                    <p className="pb-0 mb-0 text-white">
                        {getSelectedSeats.length}
                    </p>
                    <span>Seat</span>
                </div>
                <div className="col-4 m-auto text-white" style={{ borderRight: "1px solid white" }}>
                    <p className="pb-0 mb-0 text-white">₹
                        {getPrice(getSelectedSeats)}
                    </p>
                    <span>Total Price</span>
                </div>
                <div className="col-4 m-auto pt-1 text-white">
                    {getSelectedSeats.length !== 0 && 
                        <a
                        type="button"
                        onClick={(e) => ProceedToBooking(e, location.state)}
                        className="btn btn-xs rounded-sm  font-500 text-uppercase shadow-xl"
                        style={{ backgroundColor: "#ff6600", color: "white" }}
                        data-menu="menu-search"
                        >
                            Proceed
                        </a>
                    }
                </div>
            </div>
        </>
    )
}

export {BusCaochPositionView};