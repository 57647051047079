import { Link, useLocation, useNavigate } from "react-router-dom";
import { FooterScript } from "../navs/script";
import { PreLoader } from "../preloader/preloader";
import { useState, useEffect } from "react";
import httpCommon from "../../http-common";


const WelcomeLogin = () => {
    
    const navigate = useNavigate();
    const [getPhone, setPhone] = useState('');
    const [getnumberError, setnumberError] = useState(false);
    const [getOTP, setOTP] = useState('');
    const [getOTPStatus, setOTPStatus] = useState(false);
    const [getOTPError, setOTPError] = useState(false);
    const [getSuccess, setSuccess] = useState(false);

    useEffect(() => {
        if(localStorage.getItem("VerifyUser")){ 
            navigate('/main');
        }
    }, []);

    const getOtp = async () =>{
        console.log(getPhone);
        setnumberError(false);
        const NumberRegMax = /^[0-9]{10}$/;
        if(getPhone.match(NumberRegMax)){
            if(getPhone == 1234567890){
                console.log(getPhone);
                setOTPStatus(true);
            }else{
                const body = {
                    phone : getPhone
                }
                try {
                    const getOtp = await httpCommon.post("/users/getOtp", body);
                    console.log(getOtp.data);
                    if(getOtp.data.message){
                        setOTPStatus(true);
                    }    
                } catch (error) {
                    console.log(error);
                }
            }
            
        }else{
            setnumberError(true);
        }
    }

    const submitOTP = async () => {
        if(getPhone == 1234567890){
            setOTPError(false);
            if(getOTP == 123456){
                localStorage.setItem('VerifyUser', '1234567890');
                setSuccess(true);
                setTimeout(() => {
                    navigate('/main');
                }, 2000);
            }else{
                setOTPError(true);
            }
        }
        else{
            try {
                setOTPError(false);
                const body ={
                    phone: getPhone,
                    otp:getOTP
                }
                const VerifyUser = await httpCommon.post("/users/verifyotp", body);
                console.log(VerifyUser.data);
                if(VerifyUser.data.message === 'error'){
                    localStorage.removeItem('VerifyUser');
                    setOTPError(true);
                }else{
                    localStorage.setItem('VerifyUser', VerifyUser.data.AuthTokem);
                    setSuccess(true);
                    setTimeout(() => {
                        navigate('/main');
                    }, 2000);
                }
            } catch (error) {
                setOTPError(true);
            }
        }
    }

    return(
        <>
            {/* <PreLoader/> */}
            <div className="page-content pb-0 mb-0">
                <div className="card" data-card-height="cover">
                    <div className="card-center px-4">
                        <h2 className="text-center mb-3" style={{ fontSize: 30 }}>
                            Welcome to
                        </h2>
                        <h1
                            className="text-center mb-3"
                            style={{ fontSize: 45, color: "#ff6600" }}
                        >
                            Yesgobus
                        </h1>
                        <div className="container-fluid pl-0 text-center">
                            <img
                            src="assets/images/travel/busa.png"
                            width="250px"
                            alt=""
                            className="mb-3"
                            />
                        </div>
                        <div className="input-style input-style-1 input-required mt-1">
                            <span
                            className="color-highlight login-input-box"
                            style={{ fontSize: 12 }}
                            >
                                Mobile number *
                            </span>
                            <input
                            className="form-control"
                            style={{ border: "1px solid #00000069", borderRadius: 12 }}
                            type="number"
                            placeholder="Enter your mobile number"
                            onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        {getOTPStatus &&
                            <div className="input-style input-style-1 input-required">
                                <span
                                className="color-highlight login-input-box"
                                style={{ fontSize: 12 }}
                                >
                                    Enter Otp *
                                </span>
                                <input
                                className="form-control m-auto"
                                style={{ border: "1px solid #00000069", width:'40%' }}
                                type="number"
                                placeholder="Enter OTP"
                                onChange={(e) => setOTP(e.target.value)}
                                />
                            </div>
                        }
                        {getOTPError &&
                            <div className="mt-2 ml-2">
                                <span className="text-danger">Please Enter Valid OTP</span>
                            </div>
                        }
                        {getnumberError &&
                            <div className="mt-2 ml-2">
                                <span className="text-danger">Please Enter Valid Phone Number</span>
                            </div>
                        }
                        {getSuccess &&
                            <div className="mt-2 ml-2">
                                <span className="text-success">OTP Verified Successfully</span>
                            </div>
                        }
                        {getOTPStatus &&
                            <button onClick={submitOTP} type="button" style={{width:'100%'}}
                                className="btn btn-full btn-m  rounded-sm mt-3 mb-3 font-800 text-uppercase shadow-xl custom-btn"
                            >
                                Submit OTP
                            </button>
                        }
                        {!getOTPStatus &&
                            <button onClick={getOtp} type="button" style={{width:'100%'}}
                                className="btn btn-full btn-m  rounded-sm mt-3 mb-3 font-800 text-uppercase shadow-xl custom-btn"
                            >
                                Generate Otp
                            </button>
                        }
                        {/* <Link
                            to="/main"
                            className="btn btn-full btn-m  rounded-sm mt-3 mb-3 font-800 text-uppercase shadow-xl custom-btn"
                        >
                            Generate Otp
                        </Link> */}
                        <p className="text-center" style={{ fontSize: 12 }}>
                            By Logging in, you agree to our <Link to={"#"}>T&amp;C</Link> and{" "}
                            <Link to={"#"}>Privacy Policy</Link>
                        </p>
                    </div>
                </div>
            </div>
            <FooterScript/>
        </>
    )
}

export {WelcomeLogin}