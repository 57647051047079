import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavTwo } from "../navs/topnavtwo";
import { ProfileContent } from "./profilecontent";

const Profile = () => {
    return(
        <>
            <TopNavTwo children={"My Profile"}/>
            <FooterNav children={"profile"}/>
            <ProfileContent />
            <FooterScript/>
        </>
    )
}

export {Profile};