const OffersContent = () => {
    return(
        <>
            {/* Offers content starts here*/}
            <div className="page-content header-clear">
                <div className="content mb-2">
                    <div className="list-group list-boxes">
                        <a href="#" className="border bg-theme rounded-s shadow-xl">
                            <img src="assets/images/travel/fly.jpg" alt="" width="100px" height="100px" style={{borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'}} />
                            <span>Flat 10% off on Domestic Flights</span>
                            <strong className="mt-3">Yes bank credit card</strong>
                        </a>        
                        <a href="#" className="border bg-theme rounded-s shadow-xl">
                            <img src="assets/images/travel/hotl.jpg" alt="" width="100px" height="100px" style={{borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'}} />
                            <span>Flat 25% off on Domestic Flight</span>
                            <strong className="mt-3">Yes bank credit card</strong>
                        </a>        
                        <a href="#" className="border bg-theme rounded-s shadow-xl">
                            <img src="assets/images/travel/Capture.PNG" alt="" width="100px" height="100px" style={{borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'}} />
                            <span>Flat 300₹ off on Train Booking</span>
                            <strong className="mt-3">Yes bank credit card</strong>
                        </a>        
                        <a href="#" className="border bg-theme rounded-s shadow-xl">
                            <img src="assets/images/travel/txi.jpg" alt="" width="100px" height="100px" style={{borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'}} />
                            <span>Flat 500₹ off on Cab Booking</span>
                            <strong className="mt-3">Yes bank credit card</strong>
                        </a>        
                        <a href="#" className="border bg-theme rounded-s shadow-xl">
                            <img src="assets/images/travel/bus.JPG" alt="" width="100px" height="100px" style={{borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'}} />
                            <span>Flat 20₹ off on Bus Booking</span>
                            <strong className="mt-3">Yes bank credit card</strong>
                        </a>        
                        <a href="#" className="border bg-theme rounded-s shadow-xl">
                            <img src="assets/images/travel/air.jpg" alt="" width="100px" height="100px" style={{borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'}} />
                            <span>Flat 10% off on Domestic Flights</span>
                            <strong className="mt-3">Yes bank credit card</strong>
                        </a>   
                    </div>
                </div>  
            </div>
            {/* Offers content ends here*/}

        </>
    )
}

export {OffersContent};