import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetJourneyList } from "../allbuscalls/getjourneylist";
import { BusList } from "./getbuslist";
import { getVrlBuses, getOrangeBuses, getSrsBuses } from "../../redux/slices/buses";
import { getVrlSources, getOrangeSources, getSRSSources } from "../../redux/slices/sources";
import { useDispatch, useSelector } from "../../redux/store";
import format from "date-fns/format";

const ViewBusListContent = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [getBuses, setBuses] = useState([]);
    const {vrlSources, orangeSources, srsSources, isLoading} = useSelector((state) => state.sources);
    const {vrlBuses, orangeBuses, srsBuses} = useSelector((state) => state.buses);
    // console.log(vrlSources, orangeSources, srsSources);

    useEffect(() => {
        if(!location.state){
            window.location.reload();
            navigate('/busbooking');
        }
    }, [])

    useEffect(() => {
        dispatch(getVrlSources());
        dispatch(getOrangeSources());
        dispatch(getSRSSources());
    }, [dispatch]);

    useEffect(() => {
        const fromCityName = location.state.getFromCityName;
        const toCityName = location.state.getToCityName;
        const JourneyDate = location.state.getJourneyDate;
        //Vrl Buses
        const VrlFromCity = vrlSources.filter(vrl => vrl.name === fromCityName);
        const VrlToCity = vrlSources.filter(vrl => vrl.name === toCityName);
        if(VrlFromCity.length > 0 && VrlToCity.length > 0){
            // console.log(VrlFromCity, VrlToCity);
            dispatch(getVrlBuses(VrlFromCity[0].id, VrlToCity[0].id, format(new Date(JourneyDate), 'dd-MM-yyyy')));
        }
        // Orange Buses
        const OrnageFromCity = orangeSources.filter(vrl => vrl.name === fromCityName);
        const OrnageToCity = orangeSources.filter(vrl => vrl.name === toCityName);
        if(OrnageFromCity.length > 0 && OrnageToCity.length > 0){
            // console.log(VrlFromCity, VrlToCity);
            dispatch(getOrangeBuses(OrnageFromCity[0].id, OrnageToCity[0].id, format(new Date(JourneyDate), 'dd-MM-yyyy')));
        }

        // Srs Buses
        const SrsFromCity = srsSources.filter(vrl => vrl.name === fromCityName);
        const SrsToCity = srsSources.filter(vrl => vrl.name === toCityName);
        // console.log(srsSources);
        if(SrsFromCity.length > 0 && SrsToCity.length > 0){
            // console.log(VrlFromCity, VrlToCity);
            dispatch(getSrsBuses(SrsFromCity[0].id, SrsToCity[0].id, format(new Date(JourneyDate), 'yyyy-MM-dd')));
        }

    }, [vrlSources, orangeSources, srsSources]);

    useEffect(() => {
        setBuses([]);
        vrlBuses.length > 0 && vrlBuses.map((vrl, i) => {
            setBuses(oldData => [...oldData, vrl])
        })
        orangeBuses.length > 0 && orangeBuses.map((vrl, i) => {
            setBuses(oldData => [...oldData, vrl])
        })
    }, [vrlBuses, orangeBuses]);
    // console.log(getBuses);

    return(
        <>
            <BusList busData={getBuses} />
        </>
    )
}

export {ViewBusListContent};