import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavThree } from "../navs/topnavthree";
import { TopNavTwo } from "../navs/topnavtwo";
import { ViewBusListContent } from "./viewbuslistcontent";

const ViewBuslist = () => {

    
    return(
        <>
            <TopNavThree />
            <FooterNav children={"main"}/>
            <ViewBusListContent/>
            <FooterScript/>
        </>
    )
}

export {ViewBuslist};