import { useEffect as Useeffect } from "react";

const UseScript = (url) => {
    Useeffect(() => {
      const script = document.createElement('script');
  
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }, [url]);
};
const FooterScript = () => {
    UseScript('/assets/scripts/jquery.js');
    UseScript('/assets/scripts/bootstrap.min.js');
    UseScript('/assets/scripts/custom.js');
    return(
        <>
        </>
    )
}

export {FooterScript};