// import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavThree } from "../navs/topnavthree";
import { GetPayment } from "./getpayment";
// import { TopNavTwo } from "../navs/topnavtwo";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";



const BusPayment = () => {

    const location = useLocation();
    // console.log(location.state);
    const navigate = useNavigate();
    useEffect(() => {
        if(!location.state){
            window.location.reload();
            navigate('/busbooking');
        }else{
            
        } 
    }, [])
    return(
        <>
            <TopNavThree />
            {/* <FooterNav children={"main"}/> */}
            <GetPayment />
            <FooterScript/>
        </>
    )
}

export {BusPayment};