import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useLayoutEffect } from 'react'

import {WelcomeLogin} from '../login/login';
import { MainPage } from "../mainpage/mainpage";
import { BusBooking } from "../busbooking/busbooking";
import { TrainBooking } from "../trainbooking/trainbooking";
import { FlightBooking } from "../flightbooking/flightbooking";
import { MyBookings } from "../bookings/bookings";
import { Profile } from "../profile/profile";
import { Offers } from "../offers/offers";
import { Notifications } from "../notifications/notifications";
import { ViewBuslist } from "../viewbuslist/viewbuslist";
import { BusCoachView } from "../buscoachview/buscoachview";
import { SelectBordingPoint } from "../bordingpoints/selectbordingpoint";
import { SelectDropingPoint } from "../dropingpoints/selectdropingpoints";
import { AddPassengerDetails } from "../addpassengerdetails/addpassengerdetails";
import { BusPayment } from "../buspayment/buspayment";
import { BookingConfirm } from "../bookingconfirm/bookingconfirm";


//Scrooll to top
const Wrapper = ({ children }) => {
    const location = useLocation()
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    return children
}

const MainRoutes = () => {
    return(
        <Router>
            <Wrapper>
                <Routes>
                    <Route exact path="/" element={<WelcomeLogin/>} />
                    <Route path="/main" element={<MainPage/>} />
                    <Route path="/busbooking" element={<BusBooking/>} />
                    <Route path="/trainbooking" element={<TrainBooking/>} />
                    <Route path="/flightbooking" element={<FlightBooking/>} />
                    <Route path="/bookings" element={<MyBookings/>} />
                    <Route path="/profile" element={<Profile/>} />
                    <Route path="/offers" element={<Offers/>} />
                    <Route path="/notifications" element={<Notifications/>} />
                    <Route path="/bussearch" element={<ViewBuslist/>} />
                    <Route path="/buscoaschview" element={<BusCoachView/>} />
                    <Route path="/selectbording" element={<SelectBordingPoint/>} />
                    <Route path="/selectdroping" element={<SelectDropingPoint/>} />
                    <Route path="/addpassengerdetails" element={<AddPassengerDetails />} />
                    <Route path="/buspayment" element={<BusPayment />} />
                    <Route path="/bookingconfirm/:booking_id/:status" element={<BookingConfirm />} />
                    <Route path="*" element={<MainPage/>} />
                </Routes>
            </Wrapper>
        </Router>
    )
}

export {MainRoutes}