import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { GetCoachPositions } from "../allbuscalls/getcoachposition";
import { BusCaochPositionView } from "./BuscoachpositionView";
import { getVrlBusLayout, getOrangeBusLayout } from "../../redux/slices/buslayout";
import { useDispatch, useSelector } from "../../redux/store";

const BusCoachViewCOntnet = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {BusLayout, isLoading} = useSelector((state) => state.buslayout);

    useEffect(() => {
        if(!location.state){
            window.location.reload();
            navigate('/busbooking');
        }       
    }, []);

    useEffect(() => {
        const serviceProvider = location.state.busData.TRAVELLSNAME;
        console.log(serviceProvider);
        if(serviceProvider === "VRL Travels"){
            const REFERENCENUMBER = location.state.busData.REFERENCENUMBER;
            dispatch(getVrlBusLayout(REFERENCENUMBER));
        }
        if(serviceProvider === "Orange Travels"){
            const REFERENCENUMBER = location.state.busData.REFERENCENUMBER;
            dispatch(getOrangeBusLayout(REFERENCENUMBER));
        }
    },[dispatch])

    return(
        <>
            {/* Offers content starts here*/}
            <BusCaochPositionView coachPosition={BusLayout}/>
            {/* Page content starts here */}
        </>
    )
}

export {BusCoachViewCOntnet};