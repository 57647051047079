import { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useNavigate } from "react-router-dom";
import { getVrlSources, getOrangeSources, getSRSSources } from "../../redux/slices/sources";
import { useDispatch, useSelector } from "../../redux/store";

const BusBookingContent = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [getCities, setCities] = useState([]);
    const [getFromCityId, setFromCityId] = useState(0);
    const [getFromCityName, setFromCityName] = useState('');
    const [getToCityId, setToCityId] = useState(0);
    const [getToCityName, setToCityName] = useState('');
    const [getJourneyDate, setJourneyDate] = useState('');
    const [getError, setError] = useState(false);

    const {vrlSources, orangeSources, srsSources, isLoading} = useSelector((state) => state.sources);
    // console.log(vrlSources, orangeSources, srsSources);
    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        // console.log(string, results)
    }

    useEffect(() => {
        dispatch(getVrlSources());
        dispatch(getOrangeSources());
        dispatch(getSRSSources());
    }, [dispatch]);

    useEffect(() => {
        setCities([]);
        vrlSources.length > 0 && vrlSources.map((vrl, i) => {
            setCities(oldData => [...oldData, vrl])
        })
        orangeSources.length > 0 && orangeSources.map((vrl, i) => {
            setCities(oldData => [...oldData, vrl])
        })
        srsSources.length > 0 && srsSources.map((vrl, i) => {
            setCities(oldData => [...oldData, vrl])
        })
    }, [vrlSources, orangeSources, srsSources]);

    const getSources = () => {
        const uniqueSources = [];
        getCities.filter(element => {
            const isDuplicate = uniqueSources.filter((source) => source.name === element.name);
            if (isDuplicate.length === 0) {
                uniqueSources.push(element);
                return true;
            }
            return false;
        });
        return uniqueSources;
    }

    // console.log(getCities);
    
    useEffect(() => {
        let today = new Date();
        let todayDate = today.toISOString().split('T')[0];
        setJourneyDate(todayDate);
    }, [])

    const handleOnHover = (result) => {
    // the item hovered
    // console.log(result)
    }
    
    const handleOnSelect = (item) => {
        // the item selected
        // console.log(item)
        setFromCityId(item.id);
        setFromCityName(item.name);
        const el = document.getElementById('menu-share');
        el.classList.remove('menu-active');
        const el2 = document.getElementsByClassName("menu-hider");
        let el3 = el2[0];
        el3.classList.remove('menu-active');
    
    }

      const handleOnSelectTo = (item) => {
        // the item selected
        // console.log(item)
        setToCityId(item.id);
        setToCityName(item.name);
        const el = document.getElementById('menu-search');
        el.classList.remove('menu-active');
        const el2 = document.getElementsByClassName("menu-hider");
        let el3 = el2[0];
        el3.classList.remove('menu-active');
      }
    
      const handleOnFocus = () => {
        // console.log('Focused')
      }
    
      const formatResult = (item) => {
        return (
          <>
            <span key={item.id} className="close-menu" style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
          </>
        )
      }

      function getDate(e){
        setJourneyDate(e.target.value);
      }

      const CitiesChange = (e) =>{
        e.preventDefault();
        let getFromCityName1 = getFromCityName
        let getToCityName1 = getToCityName
        let getFromCityId1 = getFromCityId
        let getToCityId1 = getToCityId
        setFromCityId(getToCityId1)
        setFromCityName(getToCityName1)
        setToCityId(getFromCityId1)
        setToCityName(getFromCityName1)
      }

      const Submit = (e) => {
        e.preventDefault();
        // console.log(e);
        setError(false);
        if(getFromCityId != 0 && getToCityId != 0 && getJourneyDate != ''){
            let data = {
                'getFromCityId' : getFromCityId,
                'getToCityId' : getToCityId,
                'getJourneyDate' : getJourneyDate,
                'getFromCityName' : getFromCityName,
                'getToCityName' : getToCityName,
                'redirectUrl' : "busbooking"
            }
            navigate('/bussearch', { state: data });
        }else{
            setError(true);
        }
      }

    let today = new Date();
    let todayDate = today.toISOString().split('T')[0];
    // setJourneyDate(todayDate);


    return(
        <>
            <div className="page-content header-clear">
                <div className="divider divider-margins" />
                <div className="content mb-0">
                    <div className="card p-2">
                        <div className="input-style has-icon input-style-1 input-required">
                            <i className="input-icon fa fa-bus pl-2" style={{fontSize: 'larger', bottom: '10px'}} />
                            <span className="color-highlight">Source</span>
                            <input onChange={(e) => setFromCityName(e.target.value)} type="name" className="pl-5" data-menu="menu-share" placeholder="Enter Source" value={getFromCityName}/>
                        </div>
                        <img src="assets/images/travel/arrow.png" alt="" width="30px" style={{position: 'absolute', top: '30px', backgroundColor: 'white', right: '11px'}} onClick={(e) => {CitiesChange(e)}} /> 
                        <div className="input-style has-icon input-style-1 input-required">
                            <i className="input-icon fa fa-bus pl-2" style={{fontSize: 'larger', bottom: '10px'}} />
                            <span className="color-highlight">Destination</span>
                            <input onChange={(e) => setToCityName(e.target.value)} type="name" className="pl-5" data-menu="menu-search" placeholder="Enter Destination" value={getToCityName}/>
                        </div>
                        <div className="input-style has-icon input-style-1 input-required">
                            <i className="input-icon fa fa-calendar pl-2" style={{fontSize: 'larger', bottom: '32px'}} />
                            {/* <span class="color-highlight">Select Date</span> */}
                            <em><i className="fa fa-angle-down" /></em>
                            <input type="date" min={todayDate} className="pl-5 pt-1" onChange={(e) => getDate(e)}/>
                        </div> 
                        <a type="button" onClick={(e) => {Submit(e)}} className="btn btn-full btn-xs rounded-sm  font-500 text-uppercase shadow-xl" style={{backgroundColor: '#ff6600', color:'#fff'}}>Search</a>
                        {getError && <p className="text-danger text-center mt-1">Please Select valid city or date</p>}
                    </div>
                    <div className="card card-style shadow-xl" data-card-height={175} style={{backgroundImage: 'url("assets/images/travel/bus1.JPG")'}}>
                        <div className="card-overlay bg-black opacity-10" />
                        <div className="card-bottom m-3">
                            <p className="color-white mb-0" style={{color: '#ff6600'}}>Long Weekends Ahead</p>
                            <h1 className="color-white mb-0 pb-0">Plan Your<br /> Trip in Advance</h1>
                        </div>
                    </div>
                </div>
                <div className="divider divider-margins" />
            </div>

            {/* Search Modal Starts Menu*/}
            <div id="menu-share" className="menu menu-box-modal" data-menu-height="100%" data-menu-width="100%" data-menu-effect="menu-over">
                <div className="menu-title mt-1 mb-1">
                    <h4 className="my-0 py-0 p-3">Select Source</h4>
                    <a href="#" className="close-menu" style={{color: '#ff6600'}}><i className="fa fa-times-circle" /></a>
                </div>
                <div className="page-content">
                    <div className="content mt-0 mb-0">
                        <div className="search-box search-dark shadow-xl border-0 bg-theme rounded-sm bottom-0">
                            <i className="fa fa-search" />
                            {/* <input type="text" className="border-0" placeholder="Search" data-search /> */}
                            <ReactSearchAutocomplete
                                id="from_station"
                                items={getSources()}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelect}
                                onFocus={handleOnFocus}
                                autoFocus
                                formatResult={formatResult}
                            />
                        </div>
                    </div>
                    <div className="divider divider-margins" />
                </div>
            </div>
            {/* Search Modal Ends Menu*/}
            
            {/* Search Modal2 Starts Menu*/}
            <div id="menu-search" className="menu menu-box-modal" data-menu-height="100%" data-menu-width="100%" data-menu-effect="menu-over">
                <div className="menu-title mt-1 mb-1">
                    <h4 className="my-0 py-0 p-3">Select Destination</h4>
                    <a href="#" className="close-menu" style={{color: '#ff6600'}}><i className="fa fa-times-circle" /></a>
                </div>
                <div className="page-content">
                    <div className="content mt-0 mb-0">
                        <div className="search-box search-dark shadow-xl border-0 bg-theme rounded-sm bottom-0">
                            <i className="fa fa-search" />
                            {/* <input type="text" className="border-0" placeholder="Search" data-search /> */}
                            <ReactSearchAutocomplete
                                items={getSources()}
                                onSearch={handleOnSearch}
                                onHover={handleOnHover}
                                onSelect={handleOnSelectTo}
                                onFocus={handleOnFocus}
                                autoFocus
                                formatResult={formatResult}
                            />
                        </div>
                    </div>
                    <div className="divider divider-margins" />
                </div>
            </div>
            {/* Search Modal2 Ends Menu*/}


        </>
    )
}

export {BusBookingContent};