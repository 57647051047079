import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';

const GetPassengerData = () => {

    const location = useLocation();
    // console.log(location.state);
    const navigate = useNavigate();
    const [getData, setData] = useState([]);
    const [getDropingPoint, setDropingPoint] = useState([]);
    const [getBoardingPoint, setBoardingPoint] = useState([]);
    const [getSelectedSeats, setSelectedSeats] = useState([]);
    const [getInitialValues, setInitialValues] = useState([]);

    

    useEffect(() => {
        if(!location.state){
            window.location.reload();
            navigate('/busbooking');
        }else{
            // console.log(location.state);
            setDropingPoint(location.state.SELECTEDDROPINGPOINT);
            setBoardingPoint(location.state.SELECTEDBORDINGPOINT);
            setSelectedSeats(location.state.SELECTEDSEATS);
            setData(location.state);
        } 
    }, [])
    
    let pasengers = getSelectedSeats?.map && getSelectedSeats.map((data) => {
        var pas ={
            NAME : '',
            AGE : '',
            GENDER: '',
            SEATID : data.SEATID,
            ISLADIESSEAT : data.ISLADIESSEAT,
        }
        return pas;
    })
    

    var initialValues = {
        email : '',
        phone: '',
        passengersNames:pasengers,
    };

    // console.log(initialValues);

    function Proceed(e, data, drPoints){
        data.SELECTEDDROPINGPOINT = drPoints;
        data.redirectUrl = "selectdroping";
        navigate('/addpassengerdetails', { state : data });
    }

    var NumberRegMax = /^[0-9]{10}$/;
    const validationSchema = Yup.object({

        phone: Yup.string().required("Phone Number Required")
               .matches(NumberRegMax, "Enter Valid Phone Number"),
        email: Yup.string()
               .required("Email Required")
               .email("Enter Valid Email"),
        passengersNames : Yup.array().of(
            Yup.object().shape({
                NAME: Yup.string().required("Name Required"),
                AGE: Yup.number().required("Age Required").min(1).max(150),
                GENDER: Yup.string().required("Gender Required"),
            })
        )

    })

    const onSubmit = (data) => {
        let redata = getData;
        redata.redirectUrl = "addpassengerdetails";
        redata.USERDETAILS = data.passengersNames;
        redata.USEREMAIL = data.email;
        redata.USERPHONE = data.phone;
        // console.log(redata);
        navigate('/buspayment', { state : redata });
    }

    function chnageGender(e, addID, removeID, genderType){

        var addEle = document.getElementById(addID);
        var RemoveEle = document.getElementById(removeID);
        if(genderType === "Male"){
            addEle.style.backgroundImage = 'url("assets/images/travel/active_men.png")';
            RemoveEle.style.backgroundImage = 'url("assets/images/travel/inactive_woman.png")';
        }else if(genderType === "Female"){
            addEle.style.backgroundImage = 'url("assets/images/travel/active_women.png")';
            RemoveEle.style.backgroundImage = 'url("assets/images/travel/inactive_man.png")';
        }
    }


    return(
        <>
            {/* Page content starts here*/}
            <div className="page-content header-clear">
                <div className="content mb-2">
                <div className="timeline-body timeline-body-center bg-white pb-1 pt-0">
                    <div className="timeline-item mt-2">
                    <div
                        className="timeline-item-content rounded-s shadow-l mt-1"
                        style={{ padding: 15,backgroundColor:'bisque' }}
                    >
                        <div className="row mb-0">
                        <div className="col-12 mb-0">
                            <h5 className="font-16 text-center" style={{color:'#ff6600'}}>{getData.busData && getData.busData.TRAVELLSNAME && getData.busData.TRAVELLSNAME}</h5>
                        </div>
                        <div className=" col-12 mb-2 divider bg-gray-dark"></div>
                        <div className="col-5">
                            <h6 className="font-13 mb-0">{getData.getFromCityName && getData.getFromCityName}</h6>
                            <p className="font-8 font-weight-bold mb-0">{getBoardingPoint.STOPNAME && getBoardingPoint.STOPNAME}</p>
                            <p className="font-11 font-weight-bold mb-0">{getBoardingPoint.STOPDATE && getBoardingPoint.STOPDATE}, {getBoardingPoint.STOPTIME && getBoardingPoint.STOPTIME}</p>
                        </div>
                        <div className="col-2 m-auto text-center">
                            <i className="fa fa-arrow-right" aria-hidden="true" />
                        </div>
                        <div className="col-5 text-right">
                        <h6 className="font-13 mb-0">{getData.getToCityName && getData.getToCityName}</h6>
                            <p className="font-8 font-weight-bold mb-0">{getDropingPoint.STOPNAME && getDropingPoint.STOPNAME}</p>
                            <p className="font-11 font-weight-bold mb-0">{getDropingPoint.STOPDATE && getDropingPoint.STOPDATE}, {getDropingPoint.STOPTIME && getDropingPoint.STOPTIME}</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <Formik 
                    enableReinitialize
                    initialValues={initialValues} 
                    validationSchema={validationSchema} 
                    onSubmit={onSubmit}
                    render={({ values }) => (
                    <Form>
                        <div className="timeline-body timeline-body-center bg-white pb-1 pt-0">
                            <div className="timeline-item mt-2">
                                <div
                                    className="timeline-item-content rounded-s shadow-l mt-1"
                                    style={{ padding: 15,backgroundColor:'bisque' }}
                                >
                                    <div className="row mb-1">
                                        <div className="col-12">
                                            <p className="font-11 mb-0 text-dark">
                                                Enter your e-mail to get ticket info
                                            </p>
                                            <div className="input-style input-style-1">
                                                <Field type="text" placeholder="Email" name="email"/>
                                            </div>
                                            <ErrorMessage component={"span"} className="text-danger" style={{color:'red'}} name="email" />
                                            <div className="input-style input-style-1">
                                                <Field type="text" placeholder="Phone" name="phone"/>
                                            </div>
                                            <ErrorMessage component={"span"} className="text-danger" style={{color:'red'}} name="phone" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="timeline-body timeline-body-center bg-white pt-0">
                            <div className="timeline-item mt-2">
                            <FieldArray
                                name="passengersNames"
                                render={arrayHelpers => {
                                const passengersNames = values.passengersNames;
                                return (
                                    <div className="timeline-item-content rounded-s shadow-l mt-1"
                                         style={{ padding: 15,backgroundColor:'bisque' }}>
                                        <p className="font-11 mb-0 text-dark">Passenger Details {values.passengersNames.length}</p>
                                        {passengersNames && passengersNames.length > 0
                                            ? passengersNames.map((user, index) => {
                                                return <div key={index}  className="row mb-1">
                                                {/* <Field
                                                    placeholder="user name"
                                                    name={`users.${index}.name`}
                                                />
                                                <ErrorMessage name={`users.${index}.name`} />
                                                <br />

                                                <Field
                                                    placeholder="user email"
                                                    name={`users.${index}.email`}
                                                />
                                                <ErrorMessage name={`users.${index}.email`} />

                                                <br /> */}
                                                <div className="col-6">
                                                    <div className="input-style input-style-1 input-required">
                                                        <Field type="text" placeholder="Full name" name={`passengersNames.${index}.NAME`}/>
                                                    </div>
                                                    <ErrorMessage component={"span"} className="text-danger" name={`passengersNames.${index}.NAME`} />
                                                </div>
                                                <div className="col-2">
                                                    <div className="input-style input-style-1 input-required">
                                                        <Field type="text" placeholder="Age" name={`passengersNames.${index}.AGE`}/>
                                                    </div>
                                                    <ErrorMessage component={"span"} className="text-danger" name={`passengersNames.${index}.AGE`} />
                                                </div>
                                                <div className="col-4">
                                                    <div className="row p-0 m-0">
                                                        <div className="col-6">
                                                            {user.ISLADIESSEAT && user.ISLADIESSEAT === "Y" ? 
                                                                    <label htmlFor={`malegender${index}`} style={{backgroundImage:'url("assets/images/travel/banneduser.png")', height:'30px'}} className="l-berth seat-bg">
                                                                    </label>
                                                            :
                                                                <>
                                                                    <Field style={{display:'none'}} type="radio" id={`malegender${index}`} value="Male"  name={`passengersNames.${index}.GENDER`}/>
                                                                    <label onClick={(e) => chnageGender(e, `malegenderl${index}`, `femalegenderl${index}`, "Male")}  id={`malegenderl${index}`} htmlFor={`malegender${index}`} style={{backgroundImage:'url("assets/images/travel/inactive_man.png")', height:'30px'}} className="l-berth seat-bg">
                                                                    </label>
                                                                </>   
                                                            }
                                                        </div>
                                                        <div className="col-6">
                                                            <Field style={{display:'none'}} type="radio" id={`femalegender${index}`} value="Female" name={`passengersNames.${index}.GENDER`}/>
                                                            <label onClick={(e) => chnageGender(e, `femalegenderl${index}`, `malegenderl${index}`, "Female")} id={`femalegenderl${index}`} htmlFor={`femalegender${index}`} style={{backgroundImage:'url("assets/images/travel/inactive_woman.png")', height:'30px'}} className="l-berth seat-bg">
                                                            </label>
                                                        </div>
                                                        <ErrorMessage component={"span"} className="text-danger col-12" name={`passengersNames.${index}.GENDER`} />
                                                    </div>
                                                </div>
                                                
                                                </div>
                                            })
                                            : 'Test'}
                                    </div>
                                );
                                }}
                            />
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-xs rounded-sm font-500 m-2"
                            style={{ backgroundColor: "#ff6600", width:'100%' }}
                        >
                            Proceed to payment
                        </button>
                    </Form>
                    )}
                />
                </div>
            </div>
        </>
    )
}

export {GetPassengerData};