import { Link } from "react-router-dom"

const TopNav = () => {
    return(
        <div className="header header-fixed header-logo-app mb-1 d-flex custom-header">
            <div className="col-sm-4 pl-1">
                <Link to={"/profile"}>
                    <img
                        className="main-header-icon"
                        src="assets/images/offer/user8.png"
                        alt=""
                    />
                </Link>
            </div>
            <div className="col-sm-4"></div>
            <div className="col-sm-4 pr-1">
                <Link to={'/notifications'}>
                    <img
                        className="main-header-icon-right"
                        src="assets/images/offer/notify1.png"
                        alt=""
                    />
                </Link>
            </div>
        </div>
    )
}

export {TopNav};