import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './redux/store';
// import ScrollToTop from './scroll';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <ScrollToTop /> */}
          <App />
        </PersistGate>
      </ReduxProvider>
  </React.StrictMode>
);

reportWebVitals();
