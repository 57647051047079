import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';

const TopNavThree = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return(
        <>  
            {location.state ? 
                <div className="header header-fixed header-logo-app custom-header">
                    <a type="button" className="header-title text-white" style={{lineHeight : 'unset'}}>
                        {location.state.getFromCityName && location.state.getToCityName && location.state.getFromCityName+" -> "+location.state.getToCityName }
                        &nbsp;<span className="text-capitalize">{location.state.COACH?.map && location.state.COACH.map((coachname) => "( VRL Travels / "+coachname+" )")}</span>
                        &nbsp;{moment(location.state.getJourneyDate).format('dddd, MMMM Do YYYY')}
                    </a>
                    <a type="button" onClick={() => navigate(-1)} className="header-icon header-icon-1 text-white">
                        <i className="fas fa-arrow-left"></i>
                    </a>
                </div>
            :
                <></>
            }
        </>
    )
}

export {TopNavThree};