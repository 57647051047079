import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const GetDropingPoints = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [getDropingPoints, setDropingPoints] = useState([]);
    useEffect(() => {
        if(!location.state){
            window.location.reload();
            navigate('/busbooking');
        }else{
            setDropingPoints(location.state.busData.DROPINGPOINTS);
        }   
    }, [])

    function Proceed(e, data, drPoints){
        data.SELECTEDDROPINGPOINT = drPoints;
        data.redirectUrl = "selectdroping";
        navigate('/addpassengerdetails', { state : data });
    }

    return(
        <>
            <div className="page-content header-clear">
                <h6 className="text-center pt-3 pb-2">Choose Droping Point</h6>
                <div className="content mt-3 mb-2">
                    {getDropingPoints?.map && getDropingPoints.map((drPoints, i) => {
                        return <div type="button" onClick={(e) => Proceed(e, location.state, drPoints)} className="row border-bottom mb-4" key={i}>
                                    <div className="col-9 pl-1">
                                        <p>
                                            &nbsp;<img
                                            src="assets/images/travel/board2.png"
                                            alt=""
                                            width="23px"
                                            className="mr-2"
                                            />
                                            {drPoints.STOPNAME}
                                        </p>
                                    </div>
                                    <div className="col-3">
                                        <p className="text-right">
                                            {drPoints.STOPTIME}
                                        </p>
                                    </div>
                                </div>
                    })}
                </div>
            </div>
        </>
    )
}

export {GetDropingPoints};