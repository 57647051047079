import { MainRoutes } from "./components/routes/mainroutes";
function App() {
  return (
    <div id="page">
      <MainRoutes/>
    </div>
  )
}

export default App;
