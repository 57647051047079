import { Link, useNavigate } from "react-router-dom";

const ProfileContent = () => {
    const navigate = useNavigate();
    const Logout = () => {
        localStorage.removeItem('VerifyUser');
        navigate('/');
    } 
    return(
        <>
            {/* Profile content starts */}
            <div className="page-content">
                <div className="page-subtitle">
                </div>
                <div className="content mb-0 mt-0">
                    {/* <h2 className="mb-n1 pt-4 pb-3">
                        My Account
                    </h2> */}
                    <div className="card">
                        <div className=" list-group list-custom-small mr-2">
                            <Link to="/bookings" className="pb-2">
                                <i className="fa fa-briefcase font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>My Trips</span>
                            </Link>
                            <Link to="/offers" className="pb-2">
                                <i className="fa fa-percent font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>My Offers</span>
                            </Link>
                            <Link to="#" className="pb-2">
                                <i className="fa fa-info font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>Help</span>
                            </Link>      
                            <Link to="#" className=" pb-2">
                                <i className="fa fa-cog font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>Settings</span>
                            </Link>     
                            <Link to="#" className="pb-2">
                                <i className="fa fa-window-maximize font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>FAQs</span>
                            </Link>
                            <Link to="#" className="pb-2">
                                <i className="fa fa-file font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>Terms &amp; Conditions</span>
                            </Link>
                            <Link to="#" className=" pb-2">
                                <i className="fa fa-file font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>Privacy Policy</span>
                            </Link>
                            <Link to="#" className="pb-2">
                                <i className="fa fa-info font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>About Us</span>
                            </Link>
                            <Link to="#" className="pb-2">
                                <i className="fa fa-phone font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>Contact Us</span>
                            </Link>   
                            <a type="button" onClick={Logout} className="pb-2">
                                <i className="fa fa-sign-out-alt font-12 pl-2" style={{color: '#ff6600'}} />
                                <span>Logout</span>
                            </a>   
                        </div>  
                    </div>  
                </div>
            </div>
            {/* Profile content ends */}

        </>
    )
}

export {ProfileContent};