import { Link } from "react-router-dom"

const FooterNav = (children) => {
    let data = children;
    return(
        <div id="footer-bar" className="footer-bar-4">
            {data.children === 'profile' ?             
                <Link to="/profile" className="active-nav">
                    <i className="fa fa-user" />
                    <span>Profile</span>
                </Link>
            :
                <Link to="/profile">
                    <i className="fa fa-user" />
                    <span>Profile</span>
                </Link>
            }
            {data.children === 'bookings' ?             
                <Link to="/bookings" className="active-nav">
                    <i className="fa fa-briefcase" />
                    <span>My Bookings</span>
                </Link>
            :
                <Link to="/bookings">
                    <i className="fa fa-briefcase" />
                    <span>My Bookings</span>
                </Link>
            }
            {data.children === 'main' ?             
                <Link to="/main" className="active-nav">
                    <i className="fa fa-home" />
                    <span>Home</span>
                </Link>
            :
                <Link to="/main">
                    <i className="fa fa-home" />
                    <span>Home</span>
                </Link>
            }
            {data.children === 'insurances' ?             
                <Link to="#" className="active-nav">
                    <i className="fa fa-star" />
                    <span>My Insurances</span>
                </Link>
            :
                <Link to="#">
                    <i className="fa fa-star" />
                    <span>My Insurances</span>
                </Link>
            }
            {data.children === 'offers' ?             
                <Link to="/offers" className="active-nav">
                    <i className="fa fa-percent" />
                    <span>Offers</span>
                </Link>
            :
                <Link to="/offers">
                    <i className="fa fa-percent" />
                    <span>Offers</span>
                </Link>
            }            
        </div>
    )
}

export {FooterNav}