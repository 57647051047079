import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavTwo } from "../navs/topnavtwo";
import { FlightBookingContent } from "./filghtbookingcontent";

const FlightBooking = () => {
    return(
        <>
            <TopNavTwo children={"Flight Tickets"}/>
            <FooterNav children={"main"}/>
            <FlightBookingContent/>
            <FooterScript/>
        </>
    )
}

export {FlightBooking};