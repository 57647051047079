import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import httpCommon from "../../http-common";
import { baseUrl } from "../../http-common";

import CryptoJS from 'crypto-js'

const GetConfirm = () => {

    const {bus_booking_id} = useParams();
    const [getBookingData, setBookingData] = useState([]);
    const [getBookingStatus, setBookingSTatus] = useState(true);

    // useEffect(() => {
    //     httpCommon.get("/busseatbooking/getbookingbyorder/"+bus_booking_id).then((Response) =>{
    //         setBookingData(Response.data);
    //         if(Response.data.)
    //     }).catch((error)=>{
    //         console.log(error);
    //     })
    // }, []);
    
    return(
        <>
            {getBookingStatus ? 
                <div className="page-content pb-0 mb-0">
                <div className="card" data-card-height="cover">
                  <div className="card-center px-4" style={{ top: "45%" }}>
                    <h1
                      className="font-600 text-center text-dark mt-4"
                      style={{ letterSpacing: "0.2px", color:"#ff6600" }}
                    >
                      Thank You for choosing YesGobus
                    </h1>
                    <img
                      src="/assets/images/travel/ok.png"
                      alt=""
                      width="230px"
                      className="d-block m-auto"
                    />
                    <h1
                      className="font-600 text-center text-dark mt-4"
                      style={{ letterSpacing: "0.2px" }}
                    >
                      Booking confirmed!
                    </h1>
                    <div className="text-center">
                      <a
                        href="/bookings"
                        className="btn btn-full btn-xs rounded-sm mt-4 font-400  shadow-xl"
                        style={{
                          borderRadius: "5px !important",
                          fontSize: 15,
                          backgroundColor: "#ff6600"
                        }}
                      >
                        View Bookings
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              
             : 
            <div className="page-content pb-0 mb-0">
                <div className="card" data-card-height="cover">
                    <div className="card-center px-4" style={{ top: "45%" }}>
                    <img
                        src="/assets/images/travel/wrong.png"
                        alt=""
                        width="230px"
                        className="d-block m-auto"
                    />
                    <h1
                        className="font-600 text-center mt-4"
                        style={{ letterSpacing: "0.2px" }}
                    >
                        Sorry! something went wrong
                    </h1>
                    <h1
                        className="font-600 text-center mt-4"
                        style={{ letterSpacing: "0.2px" }}
                    >
                        Your payment has failed !
                    </h1>
                    <div className="text-center">
                        <a
                        href="#"
                        className="btn btn-full btn-xs rounded-sm mt-4 font-400  shadow-xl"
                        style={{
                            borderRadius: "5px !important",
                            fontSize: 15,
                            backgroundColor: "#ff6600"
                        }}
                        >
                        Try again
                        </a>
                    </div>
                    </div>
                </div>
                </div>
            }
        </>
    )
}

export {GetConfirm};