// import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavThree } from "../navs/topnavthree";
import { GetDropingPoints } from "./dropingpoints";
// import { TopNavTwo } from "../navs/topnavtwo";
// import { BusCoachViewCOntnet } from "./buscoachviewcontent";

const SelectDropingPoint = () => {
    return(
        <>
            <TopNavThree />
            {/* <FooterNav children={"main"}/> */}
            <GetDropingPoints/>
            <FooterScript/>
        </>
    )
}

export {SelectDropingPoint};