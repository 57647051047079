import {TopNav} from "../navs/topnav"
import { FooterNav } from "../navs/footernav"
import { FooterScript } from "../navs/script";
import { MainPageContent } from "./mainpagecontent";

const MainPage = () => {
    let Header = 'main';
    return(
        <>
            <TopNav />
            <FooterNav children={Header}/>
            <MainPageContent/>
            <FooterScript/>
        </>
    )
}

export {MainPage};