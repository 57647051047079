import axios from "axios";
export default axios.create({
  baseURL: "https://yesgobusapi.cafemobility.com/",
  // baseURL: "http://localhost:3021/",
  headers: {
    "Content-type": "application/json",
    "x-access-token": process.env.REACT_APP_API_KEY,
  }
});
export const baseUrl = "https://yesgobusapi.cafemobility.com/";
// export const baseUrl = "http://localhost:3021/";
