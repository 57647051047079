// import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavThree } from "../navs/topnavthree";
import { GetBordingPoints } from "./bordingpoints";
// import { TopNavTwo } from "../navs/topnavtwo";
// import { BusCoachViewCOntnet } from "./buscoachviewcontent";

const SelectBordingPoint = () => {
    return(
        <>
            <TopNavThree />
            {/* <FooterNav children={"main"}/> */}
            <GetBordingPoints/>
            <FooterScript/>
        </>
    )
}

export {SelectBordingPoint};