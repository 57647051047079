const NotificationsContent = () => {
    return(
        <>
            {/* Notifications content starts here*/}
            <div className="page-content header-clear">
                <div className="content mt-3 mb-2">
                    <div className="vcard-field line-height-l pb-3"><b className="text-dark">Yayyyy! You did it ! 
                        <img src="assets/images/travel/party.png" alt="" width="18px" className="pb-1" />&nbsp;
                        <img src="assets/images/travel/star.png" alt="" width="18px" className="pb-1" />&nbsp;
                        <img src="assets/images/travel/in-love.png" alt="" width="18px" className="pb-1" /></b><br />
                        <a href="#">Price decreased for your searched Bus<br />
                            <p className="text-right" style={{color: '#2a2a2a'}}>
                            2 hours ago
                            </p>
                        </a>
                        <i className="fa fa-bell mt-n2" />
                    </div>
                    <div className="vcard-field line-height-l pb-3"><b className="text-dark">Worried you'll miss the bus? 
                        <img src="assets/images/travel/sad.png" alt="" width="18px" className="pb-1" /></b><br />
                        <a href="#">Price decreased for your searched Bus<br />
                            <p className="text-right" style={{color: '#2a2a2a'}}>
                            8 hours ago
                            </p>
                        </a>
                        <i className="fa fa-bell mt-n2" />
                    </div>
                    <div className="vcard-field line-height-l pb-3"><b className="text-dark">Notification for you !
                        <img src="assets/images/travel/thinking.png" alt="" width="18px" className="pb-1" /></b><br />
                        <a href="#">Price decreased for your searched Bus<br />
                            <p className="text-right" style={{color: '#2a2a2a'}}>
                            08 Jun
                            </p>
                        </a>
                        <i className="fa fa-bell mt-n2" />
                    </div>
                    <div className="vcard-field line-height-l pb-3"><b className="text-dark">Save ₹500 on travel !
                        <img src="assets/images/travel/star.png" alt="" width="18px" className="pb-1" /></b><br />
                        <a href="#">Price decreased for your searched Bus<br />
                            <p className="text-right" style={{color: '#2a2a2a'}}>
                            06 Jun
                            </p>
                        </a>
                        <i className="fa fa-bell mt-n2" />
                    </div>
                    <div className="vcard-field line-height-l pb-3"><b className="text-dark">You have got coupon !
                        <img src="assets/images/travel/star.png" alt="" width="18px" className="pb-1" /></b><br />
                        <a href="#">Price decreased for your searched Bus<br />
                            <p className="text-right" style={{color: '#2a2a2a'}}>
                            05 Jun
                            </p>
                        </a>
                        <i className="fa fa-bell mt-n2" />
                    </div>
                    <div className="vcard-field line-height-l pb-3"><b className="text-dark">It's a new week !
                        <img src="assets/images/travel/emoji.png" alt="" width="18px" className="pb-1" /></b><br />
                        <a href="#">Price decreased for your searched Bus<br />
                            <p className="text-right" style={{color: '#2a2a2a'}}>
                            04 Jun
                            </p>
                        </a>
                        <i className="fa fa-bell mt-n2" />
                    </div>
                </div>
            </div>
            {/* Notifications content ends here*/}

        </>
    )
}

export {NotificationsContent}