import { FooterNav } from "../navs/footernav";
import { FooterScript } from "../navs/script";
import { TopNavTwo } from "../navs/topnavtwo";
import { OffersContent } from "./offerscontent";

const Offers = () => {
    return(
        <>
            <TopNavTwo children={"My Offers"}/>
            <FooterNav children={"offers"}/>
            <OffersContent/>
            <FooterScript/>
        </>
    )
}

export {Offers};