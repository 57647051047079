import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BusList = (busData) => {
    const [BusDataList, setBusDataList] = useState([]);
    const [getStops, setStops] = useState([]);
    const [getStopsType, setStopsType] = useState('');

    useEffect(() => {
        if(busData.busData.length > 0){
            setBusDataList(busData.busData)
        }
    }, [busData]);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if(!location.state){
            window.location.reload();
            navigate('/busbooking');
        }
    }, [])
    // console.log(BusDataList);

    function SelectBus(e, data){
        e.preventDefault();
        console.log(data);
        let setData = {
            redirectUrl : 'bussearch',
            getFromCityId : location.state.getFromCityId,
            getToCityId : location.state.getToCityId,
            getFromCityName : location.state.getFromCityName,
            getToCityName : location.state.getToCityName,
            getJourneyDate : location.state.getJourneyDate,
            busData: data,
        }
        
        navigate('/buscoaschview', { state : setData });
    }

    function getStopPoints(e, status, points){
        e.preventDefault();
        setStopsType(status);
        setStops(points);
    }
    return(
        <>
            <div className="page-content header-clear">
                <div className="content mb-2">
                    <div className="timeline-body timeline-body-center bg-white">
                    {BusDataList.length > 0 ? 
                        <>
                            {BusDataList.map((getJourneyData, i) => 
                                <div key={i} className="timeline-item mt-2">
                                    <div className="timeline-item-content rounded-s shadow-l mt-1">
                                        <a href="#" onClick={(e) => SelectBus(e, getJourneyData)}>
                                            <div className="row mb-0">
                                                <div className="col-8">
                                                    <h6>{getJourneyData.TRAVELLSNAME && getJourneyData.TRAVELLSNAME}</h6>
                                                </div>
                                                <div className="col-4">
                                                    <h6 className="float-right">₹&nbsp;
                                                        {getJourneyData.MINIMUMSEATPRICE && getJourneyData.MINIMUMSEATPRICE}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row mb-0">
                                                <div className="col-6">
                                                    <p className="font-10 mt-1 mb-2">
                                                        {getJourneyData.COACHNAME && getJourneyData.COACHNAME}
                                                    </p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="font-10 mt-1 mb-2 float-right border" style={{padding: '0px 4px 0px 4px', backgroundColor: 'aliceblue'}}><img src="assets/images/travel/seat.png" alt="" width="14px" /> &nbsp;
                                                    {getJourneyData.SEATSAVALIABLE && getJourneyData.SEATSAVALIABLE}&nbsp;
                                                    Seats Available</p>
                                                </div>
                                            </div>
                                        </a>
                                        <p className="mb-1 mx-0 font-weight-bold border text-dark row">
                                            <span className="col-4 px-1">
                                                {getJourneyData.SHOW_BOARDING_TIME && getJourneyData.SHOW_BOARDING_TIME}
                                            </span>
                                            <span className="col-4 px-0 text-center" style={{fontWeight: 100, color: '#ff6600'}}>
                                                {getJourneyData.JOURNEY_DURATION && getJourneyData.JOURNEY_DURATION}
                                            </span>
                                            <span className="col-4 text-right px-1">
                                                {getJourneyData.SHOW_DROPING_TIME && getJourneyData.SHOW_DROPING_TIME}&nbsp;
                                            </span>
                                        </p>
                                        <div className="row mb-1 px-1 mx-0 mt-2">
                                            <a type="button" data-toggle="modal" data-target="#stopPoints" onClick={(e) => getStopPoints(e, 'Boarding points', getJourneyData.BOARDINGPOINTS)} className="col-6 color-theme opacity-70 font-10 px-0"><i className="fa fa-hourglass-start pr-1 font-12" />Boarding points</a>
                                            <a type="button" data-toggle="modal" data-target="#stopPoints" onClick={(e) => getStopPoints(e, 'Dropping points', getJourneyData.DROPINGPOINTS)} className="col-6 text-right color-theme opacity-70 font-10 px-0"><i className="fa fa-hourglass-end pr-1 font-12" />Dropping points</a>
                                            {/* <a type="button" className="col-4 text-right color-theme opacity-70 font-10 pr-0 pl-0"><i className="fa fa-globe pr-1 font-12" />Travel policy</a> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                        : 
                        <>
                            <p className="text-center font-weight-bold">NO JOURNEY FOUND</p>
                            {/* Page content starts 
                            <div className="card" data-card-height="cover">
                                    <div className="card-center text-center">
                                    <img
                                        src="assets/images/travel/nobus.png"
                                        alt=""
                                        className="bookngs-img"
                                    />
                                    <h6 className="font-18 mt-n2 color-highlight pt-4 mb-4 font-weight-normal">
                                        No buses found in this route or selected date. Please select another
                                        route or date.
                                    </h6>
                                    <a
                                        href="#" onClick={() => navigate(-1)}
                                        className="btn-sm  mb-3 mt-5 rounded-xs text-uppercase font-400 shadow-s color-white custom-btn"
                                        style={{ padding: "8px 20px" }}
                                    >
                                        {"<-"} Back
                                    </a>
                                    </div>
                                </div>
                                    Page content ends */}
                        </>
                    }
                    </div>
                </div>  
            </div>
            {/* Page content ends here*/}
            {/* Boarding points Modal Starts */}
            <div className="modal fade" id="stopPoints">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{getStopsType}</h5>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="page-content" style={{overflowY: 'scroll', height: '300px'}}>
                            <div className="content mt-2">
                                <div className="row">
                                    <div className="col-12">
                                        {getStops?.map && getStops.map((stops, n) => 
                                            <div key={n} className="row">
                                                <div className="col-1">
                                                    <p><i className="fa fa-map-pin pr-1" style={{color: '#ff6600'}} aria-hidden="true" /></p>
                                                </div>
                                                <div className="col-7">
                                                    <p>{stops.STOPNAME && stops.STOPNAME}</p>
                                                </div>
                                                <div className="col-1">
                                                    <p>-</p>
                                                </div>
                                                <div className="col-3">
                                                    <p>{stops.STOPTIME}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  Boarding points Modal Ends */}
        </>
    )
}

export {BusList};