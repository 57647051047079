import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import format from "date-fns/format";
import httpCommon from '../../http-common';
// utils
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    BusLayout: [],
};

const slice = createSlice({
    name: 'vrlSources',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET VRL BUSES
        getVrlBusLayoutSuccess(state, action) {
            state.isLoading = false;
            state.BusLayout = action.payload;
        },
        
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getVrlBusLayout(ReferenceNumber) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const OrangeData = {
                "verifyCall" : process.env.REACT_APP_VRLVerifyCall,
                "ReferenceNumber" : ReferenceNumber
            };
            const response = await httpCommon.post('busbooking/vrlapis/GetSeatArrangementDetailsV3', OrangeData);
            console.log(response.data);
            let data = [];
            response.data.data && response.data.data.ITSSeatDetails?.map && response.data.data.ITSSeatDetails.map((seats, index) => {            
                let Seatdata ={
                    SEATID : seats.SeatNo,
                    SEATNAME : seats.SeatNo,
                    ROW : seats.Row,
                    COLUMN : seats.Column,
                    ISLADIESSEAT : seats.IsLadiesSeat,
                    BASE_FARE : seats.BaseFare,
                    BASE_FAREWITH_GST_TAX : seats.SeatRate,
                    GST_TAX: 0,
                    ROWSPAN : seats.RowSpan,
                    COLSPAN : seats.ColumnSpan,
                    UPLOWBEARTH : seats.UpLowBerth,
                    SEATTYPENAME:'',
                    SEATTYPE: seats.SeatType === 1 ? 'Berth' : 'Seater', 
                    AVAILABLE : seats.Available,
                    BOOKINGGENDER: (seats.Available === 'N' && seats.IsLadiesSeat === 'Y') ? 'FEMALE' : '',
                    JsonAllData: seats,
                };
                data.push(Seatdata);
            });
            dispatch(slice.actions.getVrlBusLayoutSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getOrangeBusLayout(ReferenceNumber) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const OrangeData = {
                "verifyCall" : process.env.REACT_APP_OrangeVerifyCall,
                "ReferenceNumber" : ReferenceNumber
            };
            const response = await httpCommon.post('busbooking/orangeapis/GetSeatArrangementDetailsV3', OrangeData);
            console.log(response.data);
            let data = [];
            response.data.data && response.data.data.ITSSeatDetails?.map && response.data.data.ITSSeatDetails.map((seats, index) => {            
                let Seatdata ={
                    SEATID : seats.SeatNo,
                    SEATNAME : seats.SeatNo,
                    ROW : seats.Row,
                    COLUMN : seats.Column,
                    ISLADIESSEAT : seats.IsLadiesSeat,
                    BASE_FARE : seats.BaseFare,
                    BASE_FAREWITH_GST_TAX : seats.SeatRate,
                    GST_TAX: seats.serServiceTax,
                    ROWSPAN : seats.RowSpan,
                    COLSPAN : seats.ColumnSpan,
                    UPLOWBEARTH : seats.UpLowBerth,
                    SEATTYPENAME:'',
                    SEATTYPE: seats.SeatType === 1 ? 'Berth' : 'Seater', 
                    AVAILABLE : seats.Available,
                    BOOKINGGENDER: (seats.Available === 'N' && seats.IsLadiesSeat === 'Y') ? 'FEMALE' : '',
                    JsonAllData: seats,
                };
                data.push(Seatdata);
            });
            dispatch(slice.actions.getVrlBusLayoutSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}