const TrainBookingContent = () => {
    return(
        <>
            <div className="page-content header-clear">
                <div className="divider divider-margins" />
                <div className="content mb-0">
                    <div className="card p-2">
                        <div className="input-style has-icon input-style-1 input-required">
                            <i className="input-icon fa fa-train pl-2" style={{fontSize: 'large', bottom: '28px'}} />
                            <span className="color-highlight" style={{paddingLeft: '35px!important'}}>Source Station</span>
                            <input type="name" className="pl-5" data-menu="menu-share" placeholder="Source Station" />
                        </div>
                        <img src="assets/images/travel/arrow.png" alt="" width="30px" style={{position: 'absolute', top: '44px', backgroundColor: 'white', right: '11px'}} /> 
                        <div className="input-style has-icon input-style-1 input-required">
                            <i className="input-icon fa fa-subway pl-2" style={{fontSize: 'large', bottom: '28px'}} />
                            <span className="color-highlight">Destination Station</span>
                            <input type="name" className="pl-5" data-menu="menu-search" placeholder="Destination Station" />
                        </div>
                        <div className="input-style has-icon input-style-1 input-required">
                            <i className="input-icon fa fa-calendar pl-2 pr-5" style={{fontSize: 'large', bottom: '32px'}} />
                            {/* <span class="color-highlight">Select Date</span> */}
                            <em><i className="fa fa-angle-down" /></em>
                            <input type="date" className="pl-5 pt-3" defaultValue="1980-08-26" />
                        </div> 
                        <a href="#" className="btn btn-full btn-xs rounded-sm  font-500 text-uppercase shadow-xl" style={{backgroundColor: '#ff6600'}}>Search</a>
                    </div>
                    <div className="card card-style shadow-xl" data-card-height={175} style={{backgroundImage: 'url("assets/images/travel/traino.jpg")'}}>
                        <div className="card-overlay bg-black opacity-10" />
                        <div className="card-bottom m-3">
                            {/* <p class="color-white mb-0" style="color:#ff6600">Long Weekends Ahead</p> */}
                            <h3 className="color-white mb-0 pb-0">Exclusive Offer for your<br />First Train Booking</h3>
                        </div>
                    </div>
                </div>
                <div className="divider divider-margins" />
                <div data-menu-load="menu-footer.html" />
            </div>

            {/* Search Modal Starts Menu*/}
            <div id="menu-share" className="menu menu-box-modal" data-menu-height="100%" data-menu-width="100%" data-menu-effect="menu-over">
                <div className="menu-title mt-1 mb-1">
                    <h4 className="my-0 py-0 p-3">Select Source</h4>
                    <a href="#" className="close-menu" style={{color: '#ff6600'}}><i className="fa fa-times-circle" /></a>
                </div>
                <div className="page-content">
                    <div className="content mt-0 mb-0">
                        <div className="search-box search-dark shadow-xl border-0 bg-theme rounded-sm bottom-0">
                            <i className="fa fa-search" />
                            <input type="text" className="border-0" placeholder="Search Stations " data-search />
                        </div>
                    </div>
                    <div className="divider divider-margins" />
                    <div className="content">
                        <h4>Popular Search</h4><br />
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Kolkata Howrah Junction</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>New Delhi Ndls</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Mumbai</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Bangalore City</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Pune</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Chennai</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Hyderabad</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Kolkata Howrah Junction</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Patna Junction</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Jaipur Junction</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Indore Junction</span></p>
                    </div>
                </div>
            </div>
            {/* Search Modal Ends Menu*/}
            {/* Search Modal2 Starts Menu*/}
            <div id="menu-search" className="menu menu-box-modal" data-menu-height="100%" data-menu-width="100%" data-menu-effect="menu-over">
                <div className="menu-title mt-1 mb-1">
                    <h4 className="my-0 py-0 p-3">Select Destination</h4>
                    <a href="#" className="close-menu" style={{color: '#ff6600'}}><i className="fa fa-times-circle" /></a>
                </div>
                <div className="page-content">
                    <div className="content mt-0 mb-0">
                        <div className="search-box search-dark shadow-xl border-0 bg-theme rounded-sm bottom-0">
                            <i className="fa fa-search" />
                            <input type="text" className="border-0" placeholder="Search Stations " data-search />
                        </div>
                    </div>
                    <div className="divider divider-margins" />
                    <div className="content">
                        <h4>Popular Search</h4><br />
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Kolkata Howrah Junction</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>New Delhi Ndls</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Mumbai</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Bangalore City</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Pune</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Chennai</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Hyderabad</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Kolkata Howrah Junction</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Patna Junction</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Jaipur Junction</span></p>
                        <p className="mb-4"><i className="input-icon fa fa-train pl-1 pr-2" /><span>Indore Junction</span></p>
                    </div>
                </div>
            </div>
            {/* Search Modal2 Ends Menu*/}

        </>
    )
}

export {TrainBookingContent};